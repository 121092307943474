import { useTranslation } from 'next-i18next';
import React, { ReactElement, useEffect } from 'react';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { mapSearchControlTravelPeriodToFormTravelDates } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { fdrHolidayFinderVars } from 'components/page/holiday-finder/holiday-finder.vars';
import { useFdrHolidayFinderLandingPageQuery } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-landing-page.generated';
import { FdrHolidayFinderInfoOverlay } from './fdr-holiday-finder-info-overlay';
import { FdrHolidayFinderLandingSkeleton } from './fdr-holiday-finder-landing.skeleton';
import { useFdrHolidayFinderContext } from './fdr-holiday-finder.context';
import {
  FdrHolidayFinderLandingWrapper,
  FdrHolidayFinderTextBlock,
  FdrHolidayFinderWelcomeBlock,
  FdrLandingImageWrapper,
} from './fdr-holiday-finder.styles';
import { FdrHolidayFinderPages } from './fdr-holiday-finder.types';

export function FdrHolidayFinderLanding(): ReactElement {
  const [t] = useTranslation('holidayfinder');

  const { setPage, setChildren, setTravelDates } = useFdrHolidayFinderContext();

  const { data, loading } = useFdrHolidayFinderLandingPageQuery({
    variables: fdrHolidayFinderVars,
  });

  const {
    hero,
    title,
    text,
    textBgColor,
    textFgColor,
    textBgImage,
    children,
    travelPeriod,
  } = data?.fdrHolidayFinder?.landingPage || {};

  useEffect(() => {
    const withChildren = !!children;
    const travelDates = travelPeriod
      ? mapSearchControlTravelPeriodToFormTravelDates(travelPeriod)
      : null;

    setChildren(withChildren);
    setTravelDates(travelDates);
  }, [travelPeriod, children, loading]);

  if (loading) return <FdrHolidayFinderLandingSkeleton />;

  function openVoting() {
    setPage(FdrHolidayFinderPages.FVotingPage);
  }

  return (
    <FdrHolidayFinderLandingWrapper
      windowRatio={window.innerWidth / window.innerHeight}
    >
      <FdrLandingImageWrapper>
        {hero ? <FdrImage image={hero} /> : null}
      </FdrLandingImageWrapper>
      <FdrHolidayFinderWelcomeBlock>
        <FdrHolidayFinderTextBlock
          title={title}
          text={text}
          fontColor={textFgColor}
          backgroundColor={textBgColor}
          backgroundImage={textBgImage}
        />
        <BsButton className="btn-submit" onClick={openVoting}>
          {t('play.button')}
        </BsButton>
        <FdrHolidayFinderInfoOverlay />
      </FdrHolidayFinderWelcomeBlock>
    </FdrHolidayFinderLandingWrapper>
  );
}
