import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrRecommendationListSkeleton } from 'fdr/components/local/fdr-recommendation-list/fdr-recommendation-list.skeleton';

const FdrItemSkeleton = styled(FdrRecommendationListSkeleton)(
  sx2CssThemeFn({
    pl: [null, '15px'],
    pr: [4, null],
    width: '100%',
    flexShrink: ['0', null],
    maxWidth: '100%',
    marginBottom: '20px',
  })
);

const FdrListSkeleton = styled(FdrRecommendationListSkeleton)({
  width: '100%',
});

export function FdrHolidayFinderSkeleton(): ReactElement {
  const { mobile } = useDeviceType();

  return (
    <>
      {mobile
        ? Array.from({ length: 3 }).map((_, idx) => (
            <FdrItemSkeleton key={idx} />
          ))
        : Array.from({ length: 2 }).map((_, idx) => (
            <FdrListSkeleton key={idx} />
          ))}
    </>
  );
}
