import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrTextBgImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-text-bg-image.generated';
import { FdrImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-image.generated';
import { FdrResizedFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-resized.generated';
import { FdrTravelPeriodFragmentDoc } from 'fdr/schemas/fragment/travel-dates/fdr-travel-period.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHolidayFinderLandingPageQueryVariables = Types.Exact<{
  textBgImageCriteria: Array<Types.FdrImageCriteria> | Types.FdrImageCriteria;
}>;

export type FdrHolidayFinderLandingPageQuery = {
  __typename?: 'Query';
  fdrHolidayFinder?: {
    __typename?: 'FdrHolidayFinderPages';
    landingPage?: {
      __typename?: 'FdrHolidayFinderLandingPage';
      text?: string | null;
      title?: string | null;
      textFgColor?: string | null;
      textBgColor?: string | null;
      children: boolean;
      textBgImage?: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      } | null;
      hero?: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      } | null;
      travelPeriod: {
        __typename?: 'FdrSearchControlsTravelPeriod';
        defaultPeriod: Types.FdrTravelPeriodType;
        exactDepartureDate: any;
        exactReturnDate: any;
        flexibleDepartureDate: any;
        flexibleReturnDate: any;
        flexibleDurations: Array<number>;
      };
    } | null;
  } | null;
};

export const FdrHolidayFinderLandingPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHolidayFinderLandingPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textBgImageCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FdrImageCriteria' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHolidayFinder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textFgColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textBgColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textBgImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrTextBgImage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hero' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrImage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resized' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'criteria' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '1x_desktop',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '1920',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '800',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'mode',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'FILL',
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '2x_desktop',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '3840',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '1600',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'mode',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'FILL',
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '1x_mobile',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '800',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '320',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'mode',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'FILL',
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '2x_mobile',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '1600',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '640',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'mode',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'FILL',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrResized' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'travelPeriod' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrTravelPeriod' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrTextBgImageFragmentDoc.definitions,
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
    ...FdrTravelPeriodFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHolidayFinderLandingPageQuery__
 *
 * To run a query within a React component, call `useFdrHolidayFinderLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHolidayFinderLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHolidayFinderLandingPageQuery({
 *   variables: {
 *      textBgImageCriteria: // value for 'textBgImageCriteria'
 *   },
 * });
 */
export function useFdrHolidayFinderLandingPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrHolidayFinderLandingPageQuery,
    FdrHolidayFinderLandingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrHolidayFinderLandingPageQuery,
    FdrHolidayFinderLandingPageQueryVariables
  >(FdrHolidayFinderLandingPageDocument, options);
}
export function useFdrHolidayFinderLandingPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHolidayFinderLandingPageQuery,
    FdrHolidayFinderLandingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrHolidayFinderLandingPageQuery,
    FdrHolidayFinderLandingPageQueryVariables
  >(FdrHolidayFinderLandingPageDocument, options);
}
export type FdrHolidayFinderLandingPageQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderLandingPageQuery
>;
export type FdrHolidayFinderLandingPageLazyQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderLandingPageLazyQuery
>;
export type FdrHolidayFinderLandingPageQueryResult = Apollo.QueryResult<
  FdrHolidayFinderLandingPageQuery,
  FdrHolidayFinderLandingPageQueryVariables
>;
