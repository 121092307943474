import { IFdrTravelDatesState } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';

export enum FdrHolidayFinderPages {
  LandingPage = 'landing',
  FVotingPage = 'fVoting',
  PVotingPage = 'pVoting',
  VotingResultPage = 'results',
  SearchResultPage = 'search',
}

export type TFdrHolidayFinderState = {
  page: FdrHolidayFinderPages;
  filters: Array<string>;
  withChildren?: boolean;
  travelDates?: IFdrTravelDatesState;
  sessionId?: string;
};

export interface IFdrHolidayFinderSearchFormState {
  withChildren: boolean;
  travelDates?: IFdrTravelDatesState | null;
  productFeatures?: Array<string>;
}

export enum FdrHolidayFinderActionType {
  SET_VALUE = 'ADD_FILTER',
  SET_TRAVEL_DATES = 'SET_TRAVEL_DATES',
  SET_SESSION_ID = 'SET_SESSION_ID',
  SET_PAGE = 'SET_PAGE',
  SET_CHILDREN = 'SET_CHILDREN',
  SET_STATE_TO_DEFAULT = 'SET_STATE_TO_DEFAULT',
}

export type TFdrHolidayFinderAction =
  | {
      type: FdrHolidayFinderActionType.SET_VALUE;
      filter: string;
    }
  | {
      type: FdrHolidayFinderActionType.SET_TRAVEL_DATES;
      travelDates: IFdrTravelDatesState;
    }
  | {
      type: FdrHolidayFinderActionType.SET_SESSION_ID;
      sessionId: string;
    }
  | {
      type: FdrHolidayFinderActionType.SET_PAGE;
      page: FdrHolidayFinderPages;
    }
  | {
      type: FdrHolidayFinderActionType.SET_CHILDREN;
      withChildren: boolean;
    }
  | {
      type: FdrHolidayFinderActionType.SET_STATE_TO_DEFAULT;
      defaultState?: TFdrHolidayFinderState;
    };
