import React, { ReactElement, useEffect } from 'react';
import { useRequestContextHeaders } from '@hotelplan/fdr.lib.context.request';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { trackFinderResults } from '@hotelplan/libs.tracking';
import { FdrHolidayFinderResultList } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder-result-list';
import { FINISH_VOTING_PLACEMENT_ID } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.constants';
import { useFdrHolidayFinderContext } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.context';
import {
  mapFdrHolidayFinderProductToTrackableData,
  mapFdrTravelDatesToFdrTravelPeriod,
} from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.utils';
import { useFdrHolidayFinderVotingResultsQuery } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-voting-results.generated';

const OFFER_LIMIT_DESKTOP = 9;
const OFFER_LIMIT_MOBILE = 6;

export function FdrHolidayFinderVotingResult(): ReactElement {
  const { mobile } = useDeviceType();
  const { bd4TravelUserId } = useRequestContextHeaders();
  const { state } = useFdrHolidayFinderContext();

  const { filters, withChildren, travelDates, sessionId } = state;

  const { data, loading } = useFdrHolidayFinderVotingResultsQuery({
    variables: {
      input: {
        sessionId,
        children: withChildren,
        productFeatures: filters,
        smartSeerUserId: bd4TravelUserId,
        limit: mobile ? OFFER_LIMIT_MOBILE : OFFER_LIMIT_DESKTOP,
        placementId: FINISH_VOTING_PLACEMENT_ID,
        travelPeriod: mapFdrTravelDatesToFdrTravelPeriod(travelDates),
      },
    },
  });

  const { searchResult } =
    data?.fdrHolidayFinder?.resultPage.resultOffers || {};

  useEffect(() => {
    if (loading || !searchResult) return;

    trackFinderResults({
      cards: searchResult.productOffers.map(offer =>
        mapFdrHolidayFinderProductToTrackableData(offer)
      ),
      bd4t: {
        elementSetId: searchResult.elementSetId,
        votesCollected: searchResult.votesCollected,
        ptoken: searchResult.ptoken,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, searchResult]);

  return (
    <FdrHolidayFinderResultList
      offers={searchResult?.productOffers || []}
      loading={loading}
    />
  );
}
