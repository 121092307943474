import { useRouter } from 'next/router';
import { IFdrTravelDatesState } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { FdrTravelPeriodType } from '@hotelplan/supergraph-api';

const GIATA_IDS_GET_PARAM_NAME = 'ids';
const GIATA_IDS_SEPARATOR = ',';
const DEPARTURE_DATE_GET_PARAM_NAME = 'departure_date';
const RETURN_DATE_GET_PARAM_NAME = 'return_date';
const DURATION_TYPE_GET_PARAM_NAME = 'duration_type';
const DURATION_TYPE_GET_PARAM_EXACT = 'exact';
const DURATION_TYPE_GET_PARAM_FLEX = 'flex';
const DURATION_MIN_GET_PARAM_NAME = 'duration_min';
const DURATION_MAX_GET_PARAM_NAME = 'duration_max';

const DURATION_TYPE_SEARCH_PERIOD_TYPE_MAP = new Map<
  string,
  FdrTravelPeriodType
>([
  [DURATION_TYPE_GET_PARAM_EXACT, FdrTravelPeriodType.Exact],
  [DURATION_TYPE_GET_PARAM_FLEX, FdrTravelPeriodType.Flexible],
]);

interface IFdrHolidayFinderUrlSearchState {
  giataIds: Array<string>;
  travelDates: IFdrTravelDatesState;
}

export function useFdrHolidayFinderUrlSearchState(): IFdrHolidayFinderUrlSearchState {
  const router = useRouter();

  let giataIds = [];
  let travelDates = null;

  const queryIds = router?.query?.[GIATA_IDS_GET_PARAM_NAME] || '';
  const departureDate = router?.query?.[DEPARTURE_DATE_GET_PARAM_NAME] || '';
  const returnDate = router?.query?.[RETURN_DATE_GET_PARAM_NAME] || '';
  const durationType = router?.query?.[DURATION_TYPE_GET_PARAM_NAME] || '';
  const durationMin = router?.query?.[DURATION_MIN_GET_PARAM_NAME] || '';
  const durationMax = router?.query?.[DURATION_MAX_GET_PARAM_NAME] || '';

  function isValid(param: string | Array<string>) {
    return typeof param === 'string' && param.length;
  }

  if (isValid(queryIds)) {
    giataIds = (queryIds as string).split(GIATA_IDS_SEPARATOR);
  }

  if (
    isValid(departureDate) &&
    isValid(returnDate) &&
    isValid(durationType) &&
    isValid(durationMin) &&
    isValid(durationMax)
  ) {
    travelDates = {
      departureDate: new Date(departureDate as string),
      returnDate: new Date(returnDate as string),
      duration: {
        duration: [
          durationMin as unknown as number,
          durationMax as unknown as number,
        ],
        type: 'night',
      },
      searchType: DURATION_TYPE_SEARCH_PERIOD_TYPE_MAP.get(
        durationType as string
      ),
      extraDurations: [],
    };
  }

  return {
    giataIds,
    travelDates,
  };
}
