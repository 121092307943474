import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

export function FdrHolidayFinderResultsInfoSkeleton(): ReactElement {
  const { mobile } = useDeviceType();

  return (
    <ContentLoader
      speed={2}
      width={mobile ? 322 : 445}
      height={mobile ? 20 : 26}
      style={{
        width: '100%',
        height: mobile ? '20px' : '26px',
      }}
    >
      {mobile ? (
        <>
          <rect x="26" width="296" height="20" rx="10" />
          <rect width="20" height="20" rx="8" />
        </>
      ) : (
        <>
          <rect x="38" width="407" height="26" rx="10" />
          <rect width="28" height="26" rx="8" />
        </>
      )}
    </ContentLoader>
  );
}
