import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

export function FdrHolidayFinderLandingSkeleton(): ReactElement {
  const { mobile } = useDeviceType();

  return (
    <ContentLoader
      speed={2}
      width={mobile ? 375 : 1920}
      height={mobile ? 720 : 831}
      style={{
        width: '100%',
        height: mobile ? '720px' : '831px',
      }}
    >
      {mobile ? (
        <>
          <rect width="375" height="406" />
          <rect x="47" y="450" width="280" height="27" rx="13.5" />
          <rect x="103" y="483" width="168" height="27" rx="13.5" />
          <rect x="23" y="529" width="333" height="18" rx="9" />
          <rect x="70" y="573" width="235" height="52" rx="4" />
          <circle cx="41.5" cy="678.5" r="25.5" />
          <circle cx="333.5" cy="678.5" r="25.5" />
          <rect y="719" width="375" height="1" />
        </>
      ) : (
        <>
          <rect width="961" height="832" />
          <rect x="1138" y="323" width="613" height="54" rx="27" />
          <rect x="1222" y="394" width="433" height="30" rx="15" />
          <rect x="1323" y="459" width="234" height="52" rx="4" />
          <circle cx="1017" cy="791" r="25" />
          <rect x="960" width="959" height="1" />
          <rect x="960" y="831" width="959" height="1" />
          <rect x="1919" width="1" height="832" />
        </>
      )}
    </ContentLoader>
  );
}
