import React, { ReactElement } from 'react';
import { FdrHolidayFinderLanding } from './fdr-holiday-finder-landing';
import { useFdrHolidayFinderContext } from './fdr-holiday-finder.context';
import { FdrHolidayFinderPages } from './fdr-holiday-finder.types';
import { FdrHolidayFinderFiltersVoting } from './voting/fdr-filters-voting';
import { FdrHolidayFinderProductsVotingContainer } from './voting/fdr-products-voting-container';
import { FdrHolidayFinderVotingResult } from './voting/fdr-voting-result';

export function FdrHolidayFinderContainer(): ReactElement {
  const { state } = useFdrHolidayFinderContext();

  return (
    <>
      {
        {
          [FdrHolidayFinderPages.LandingPage]: <FdrHolidayFinderLanding />,
          [FdrHolidayFinderPages.FVotingPage]: (
            <FdrHolidayFinderFiltersVoting />
          ),
          [FdrHolidayFinderPages.PVotingPage]: (
            <FdrHolidayFinderProductsVotingContainer />
          ),
          [FdrHolidayFinderPages.VotingResultPage]: (
            <FdrHolidayFinderVotingResult />
          ),
          [FdrHolidayFinderPages.SearchResultPage]: (
            <div>{`SearchResultPage`}</div>
          ),
        }[state.page]
      }
    </>
  );
}
