import {
  IFdrTravelDatesState,
  mapTravelDatesModalToTrackableTravelDates,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { IFinderFormData, TrackableCard } from '@hotelplan/libs.tracking';
import { formatDate } from '@hotelplan/libs.utils';
import {
  FdrHolidayFinderTravelPeriodInput,
  FdrPrice,
  FdrProductTravelType,
} from '@hotelplan/supergraph-api';
import { FdrHotelHolidayFinderOfferFragment } from 'fdr/schemas/fragment/product/fdr-hotel-holiday-finder-offer.generated';
import { FdrHolidayFinderOfferFragment } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-initial-offers.generated';
import { FdrHolidayFinderProductOfferFragment } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-next-offer.generated';
import { IFdrHolidayFinderSearchFormState } from './fdr-holiday-finder.types';

export function mapFdrHolidayFinderFormStateToTrackableData(
  state: IFdrHolidayFinderSearchFormState
): IFinderFormData {
  return {
    withChildren: state.withChildren,
    productFeatures: state.productFeatures,
    travelPeriod: state.travelDates
      ? mapTravelDatesModalToTrackableTravelDates(state.travelDates)
      : null,
  };
}

export function mapFdrTravelDatesToFdrTravelPeriod(
  travelDates: IFdrTravelDatesState
): FdrHolidayFinderTravelPeriodInput {
  const { departureDate, returnDate, duration } = travelDates || {};

  return {
    returnDate: returnDate ? formatDate(returnDate) : null,
    departureDate: departureDate ? formatDate(departureDate) : null,
    durations: duration?.duration,
  };
}

export const mapFdrHolidayFinderProductToTrackableData = (
  offer: FdrHolidayFinderProductOfferFragment | FdrHolidayFinderOfferFragment
): TrackableCard => {
  return {
    id: (offer.product as FdrHotelHolidayFinderOfferFragment).id,
    price: getHolidayFinderOfferPriceByType(offer),
  };
};

function getHolidayFinderOfferPriceByType(
  offer: FdrHolidayFinderProductOfferFragment | FdrHolidayFinderOfferFragment
) {
  return {
    hotel:
      offer.travelType === FdrProductTravelType.HotelOnly
        ? offer.pricePerPerson.value.toString()
        : null,
    package:
      offer.travelType === FdrProductTravelType.Package
        ? offer.pricePerPerson.value.toString()
        : null,
  };
}

export function mapPrice(price: FdrPrice): {
  currency: string;
  amount: string;
} {
  return {
    currency: price.currency,
    amount: `${price.value}`,
  };
}
