import { CardDirection } from '@hotelplan/components.common.cards-stack';
import { useRequestContextHeaders } from '@hotelplan/fdr.lib.context.request';
import {
  trackFinderCards,
  trackFinderVote,
  VoteType,
} from '@hotelplan/libs.tracking';
import { START_VOTING_PLACEMENT_ID } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.constants';
import { useFdrHolidayFinderContext } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.context';
import { FdrHolidayFinderPages } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.types';
import {
  mapFdrHolidayFinderProductToTrackableData,
  mapFdrTravelDatesToFdrTravelPeriod,
} from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.utils';
import { FdrHotelHolidayFinderOfferFragment } from 'fdr/schemas/fragment/product/fdr-hotel-holiday-finder-offer.generated';
import { useFdrHolidayFinderNextOfferLazyQuery } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-next-offer.generated';
import { TFdrVotingCard } from './fdr-voting.types';

interface IUseFdrHolidayFinderVoteProps {
  initialElementSetId: string;
}

interface IUseFdrHolidayFinderVote {
  swipe(
    card: TFdrVotingCard,
    direction: CardDirection,
    addCardsToStack: (...cardsToAdd: Array<TFdrVotingCard>) => Promise<void>
  ): Promise<void>;
}

export function useFdrHolidayFinderVote({
  initialElementSetId,
}: IUseFdrHolidayFinderVoteProps): IUseFdrHolidayFinderVote {
  const { bd4TravelUserId } = useRequestContextHeaders();
  const { state, setSessionId, setPage } = useFdrHolidayFinderContext();

  const [vote] = useFdrHolidayFinderNextOfferLazyQuery();

  const { filters, withChildren, travelDates } = state;

  async function handleSwipe(
    card: TFdrVotingCard,
    direction: CardDirection,
    addCardsToStack: (...cardsToAdd: Array<TFdrVotingCard>) => Promise<void>
  ): Promise<void> {
    const { data: nextOffersData, loading } = await vote({
      variables: {
        input: {
          limit: 1,
          votes: [
            {
              productId: (card.product as FdrHotelHolidayFinderOfferFragment)
                .id,
              ratingValue: direction,
            },
          ],
          sessionId: initialElementSetId,
          smartSeerUserId: bd4TravelUserId,
          productFeatures: filters,
          children: withChildren,
          travelPeriod: mapFdrTravelDatesToFdrTravelPeriod(travelDates),
          placementId: START_VOTING_PLACEMENT_ID,
        },
      },
    });

    const nextOffer =
      nextOffersData?.fdrHolidayFinder?.votingPage?.nextOffers.searchResult
        .productOffers[0];

    trackFinderVote({
      type: !!direction ? VoteType.positive : VoteType.negative,
      card: mapFdrHolidayFinderProductToTrackableData(card),
    });

    if (!loading) {
      if (
        !nextOffersData?.fdrHolidayFinder?.votingPage?.nextOffers.searchResult
          .continueVoting
      ) {
        setPage(FdrHolidayFinderPages.VotingResultPage);
        setSessionId(
          nextOffersData?.fdrHolidayFinder?.votingPage?.nextOffers.searchResult
            .elementSetId
        );
      }

      trackFinderCards({
        cards: [mapFdrHolidayFinderProductToTrackableData(nextOffer)],
        bd4t: {
          elementSetId:
            nextOffersData?.fdrHolidayFinder?.votingPage?.nextOffers
              .searchResult.elementSetId,
          votesCollected:
            nextOffersData?.fdrHolidayFinder?.votingPage?.nextOffers
              .searchResult.votesCollected,
          ptoken:
            nextOffersData?.fdrHolidayFinder?.votingPage?.nextOffers
              .searchResult.ptoken,
        },
      });
    }

    await addCardsToStack(nextOffer as TFdrVotingCard);
  }

  return { swipe: handleSwipe };
}
