import merge from 'lodash/merge';
import { Trans, useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { TextInput } from '@hotelplan/components.common.text-input';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn, SxStyleProp } from '@hotelplan/util.theme.sxc';
import { LoginLink } from 'fdr/components/local/fdr-wishlist/fdr-wishlist-login-router-link';

const textInputStyles: SxStyleProp = ({ FONT_SIZE }: DefaultTheme) => ({
  input: {
    ...FONT_SIZE.LARGE,
    letterSpacing: '0.7px',
    height: ['45px', '48px'],
  },
});

interface IWishlistForm {
  value?: string;
  canToggle?: boolean;
  submitBtnText: string;
  toggleBtnText?: string;
  onSubmit: (value: string) => void;
  onClose?: () => void;
}

const WishlistFormToggleWrapper = styled.div(
  ({ theme, theme: { media, FONT_SIZE } }) =>
    sx2CssThemeFn({
      display: 'flex',
      flexDirection: 'column',
      alignItems: ['flex-start', 'flex-end'],
      textAlign: [`left`, `right`],
      '.wishlist-form-toggle-button': {
        ...FONT_SIZE.LINK_LARGE,
        border: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
        gap: '12px',
      },
      '.info-on-adding-wishlist-wrap': merge({}, theme.text.richText, {
        [media.tablet]: {
          fontSize: '18px',
        },
      }),
    })
);

const OpenedFormWrapper = styled.div(({ theme: { FONT_SIZE }, theme }) =>
  sx2CssThemeFn({
    display: 'flex',
    '.wishlist-form-input': merge({}, textInputStyles(theme), {
      marginRight: '30px',
    }),
    '.wishlist-form-submit-button': {
      flexShrink: '0',
      px: '40px',
      mr: '30px',
    },
    '.wishlist-form-close-button': {
      flexShrink: '0',
      ...FONT_SIZE.LINK_LARGE,
    },
  })
);

const WishlistAddModal = styled(WhiteBoxModal)(
  ({ theme }) =>
    sx2CssThemeFn({
      '.modal-header': theme.modals.wideModalHeader,
      '.modal-wrapper': theme.modals.wideModalWrapper,
      '.modal-body': theme.modals.commonModalBody,
      '.wishlist-form-text-input': textInputStyles(theme),
    }),
  ({ theme: { media } }) => ({
    '.modal-body': {
      paddingBottom: '75px',
      [media.tablet]: {
        paddingBottom: '40px',
      },
    },
  })
);

const WishlistForm: React.FC<IWishlistForm> = ({
  canToggle = true,
  value = '',
  submitBtnText,
  toggleBtnText,
  onSubmit,
  onClose,
}) => {
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();
  const b2cAnonymous = channelType === AuthChannelType.B2CAnonymous;

  const [t, { language }] = useTranslation(['common', 'wishlist']);
  const [name, setName] = useState<string>(value);
  const [isFormOpened, openForm, closeForm] = useToggleState(!canToggle);
  const [isInfoForNotLoggedInShown, showInfoForNotLoggedIn] =
    useState<boolean>(false);

  const onHandleSubmit = () => {
    onSubmit(name);
    setName('');
    closeForm();
  };

  return (
    <>
      {canToggle && !isFormOpened && (
        <WishlistFormToggleWrapper>
          <BsButtonWithIcon
            className="wishlist-form-toggle-button"
            icon={{
              name: 'plus-rounded',
              size: mobile ? 'md' : 'lg',
            }}
            variant={E_BUTTON_TYPE.LINK_BUTTON}
            onClick={() => {
              if (b2cAnonymous) {
                showInfoForNotLoggedIn(true);
              } else {
                openForm();
              }
            }}
          >
            {toggleBtnText || t('wishlist:form.toggle.button')}
          </BsButtonWithIcon>
          {isInfoForNotLoggedInShown && (
            <div className="info-on-adding-wishlist-wrap">
              <Trans
                i18nKey="wishlist:infoOnAddingWishlist.text"
                components={[<LoginLink key="login" language={language} />]}
              />
            </div>
          )}
        </WishlistFormToggleWrapper>
      )}
      {!mobile && isFormOpened && (
        <OpenedFormWrapper>
          <TextInput
            hideLabel
            value={name}
            className="wishlist-form-input"
            testId="wishlist-name-id"
            placeholder={t('wishlist:form.input.placeholder')}
            onChange={nextName => setName(nextName)}
          />
          <BsButton
            className="wishlist-form-submit-button"
            onClick={onHandleSubmit}
          >
            {submitBtnText}
          </BsButton>
          <BsButton
            variant={E_BUTTON_TYPE.LINK_BUTTON}
            className="wishlist-form-close-button"
            onClick={() => {
              setName('');
              closeForm();
              onClose && onClose();
            }}
          >
            {t('common:cancel')}
          </BsButton>
        </OpenedFormWrapper>
      )}
      <WishlistAddModal
        title=""
        name="wishlistAddForm"
        show={mobile && isFormOpened}
        onClose={() => {
          setName('');
          closeForm();
          onClose && onClose();
        }}
        controls={
          <BsButton
            variant={E_BUTTON_TYPE.APPLY_BUTTON}
            onClick={onHandleSubmit}
          >
            {submitBtnText}
          </BsButton>
        }
      >
        <TextInput
          hideLabel
          value={name}
          className="wishlist-form-text-input"
          testId="wishlist-name-id"
          placeholder={t('wishlist:form.input.placeholder')}
          onChange={nextName => setName(nextName)}
        />
      </WishlistAddModal>
    </>
  );
};

export default WishlistForm;
