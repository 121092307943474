import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { languageList } from '@hotelplan/libs.language';
import { FeatureList } from 'config/pageConfig.constants';
import {
  FdrInfoButton,
  FdrInfoOverlayWrap,
  FdrSwitchButtonsWrap,
  FdrUnderstoodButton,
} from './fdr-holiday-finder.styles';

const contestLinkMap = new Map<typeof languageList[number], string>([
  ['de', `/holidayfinder/teilnahmebedingungen`],
  ['fr', `/holidayfinder/conditions-de-participation`],
]);

export function FdrHolidayFinderInfoOverlay(): ReactElement {
  const [t, { language }] = useTranslation('holidayfinder');
  const { isEnabled } = useFeatureToggle();

  const [isContestShowed, , , toggleContest] = useToggleState(false);
  const [showInfoOverlay, openInfoOverlay, closeInfoOverlay] =
    useToggleState(false);

  const contestLink = contestLinkMap.get(language);

  return (
    <>
      <FdrInfoButton
        variant="circleBtn"
        icon={{ name: 'info-letter', size: 'xxlg' }}
        onClick={openInfoOverlay}
        className="info-button"
      >
        {t('info.button')}
      </FdrInfoButton>
      <FdrInfoOverlayWrap
        name="infoOverlayForm"
        onClose={closeInfoOverlay}
        show={showInfoOverlay}
        controls={
          <FdrUnderstoodButton onClick={closeInfoOverlay}>
            {t('holidayfinder:understood.button')}
          </FdrUnderstoodButton>
        }
      >
        {isEnabled(FeatureList.HOLIDAY_FINDER_CONTEST_INFO) ? (
          <FdrSwitchButtonsWrap>
            <button
              onClick={toggleContest}
              className={
                isContestShowed ? 'switch-button' : 'switch-button active'
              }
            >
              {t('infoToggle.button1')}
            </button>
            <button
              onClick={toggleContest}
              className={
                isContestShowed ? 'switch-button active' : 'switch-button'
              }
            >
              {t('infoToggle.button2')}
            </button>
          </FdrSwitchButtonsWrap>
        ) : null}
        {isContestShowed ? (
          <>
            <h2 className="info-overlay-title">{t('infoContest.title')}</h2>
            <p className="info-overlay-text">
              <span className="bold">{t('infoContest.text4')}</span>
              <ul>
                <li>{t('infoContest.text1')}</li>
                <li>{t('infoContest.text2')}</li>
                <li>{t('infoContest.text3')}</li>
              </ul>
            </p>
            <p className="info-overlay-text">
              <span className="bold">{t('infoContest.text5')}</span>
              <p>{t('infoContest.text6')}</p>
              <p>
                {t('infoContest.text7')}
                <a target="_blank" href={contestLink} rel="noreferrer">
                  {t('infoContest.link')}
                </a>
              </p>
            </p>
          </>
        ) : (
          <>
            <h2 className="info-overlay-title">{t('info.title')}</h2>
            <p className="info-overlay-text">{t('info.text1')}</p>
            <p className="info-overlay-text">{t('info.text2')}</p>
            <p className="info-overlay-text">{t('info.text3')}</p>
          </>
        )}
      </FdrInfoOverlayWrap>
    </>
  );
}
