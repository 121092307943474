import React, { ReactElement } from 'react';
import { useRequestContextHeaders } from '@hotelplan/fdr.lib.context.request';
import { trackFinderCards } from '@hotelplan/libs.tracking';
import {
  CARD_LIMIT,
  START_VOTING_PLACEMENT_ID,
} from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.constants';
import { useFdrHolidayFinderContext } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.context';
import { FdrHolidayFinderVotingWrapper } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.styles';
import {
  mapFdrHolidayFinderProductToTrackableData,
  mapFdrTravelDatesToFdrTravelPeriod,
} from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.utils';
import { useFdrHolidayFinderInitialOffersQuery } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-initial-offers.generated';
import { FdrHolidayFinderProductCardSkeleton } from './fdr-product-card.skeleton';
import { FdrHolidayFinderProductsVoting } from './fdr-products-voting';
import { TFdrVotingCard } from './fdr-voting.types';

export function FdrHolidayFinderProductsVotingContainer(): ReactElement {
  const { bd4TravelUserId } = useRequestContextHeaders();
  const { state } = useFdrHolidayFinderContext();

  const { filters, withChildren, travelDates } = state;

  const { data: initialOffersData, loading: initialOffersLoading } =
    useFdrHolidayFinderInitialOffersQuery({
      variables: {
        input: {
          limit: CARD_LIMIT,
          smartSeerUserId: bd4TravelUserId,
          productFeatures: filters,
          children: withChildren,
          travelPeriod: mapFdrTravelDatesToFdrTravelPeriod(travelDates),
          placementId: START_VOTING_PLACEMENT_ID,
        },
      },
      onCompleted(result) {
        const { offers, elementSetId, ptoken } =
          result?.fdrHolidayFinder?.landingPage?.initialOffers.searchResult ||
          {};

        trackFinderCards({
          bd4t: { elementSetId, ptoken, votesCollected: 0 },
          cards: offers?.map(offer =>
            mapFdrHolidayFinderProductToTrackableData(offer)
          ),
        });
      },
    });

  if (initialOffersLoading) {
    return (
      <FdrHolidayFinderVotingWrapper>
        <FdrHolidayFinderProductCardSkeleton />
      </FdrHolidayFinderVotingWrapper>
    );
  }

  const { offers, elementSetId } =
    initialOffersData?.fdrHolidayFinder?.landingPage?.initialOffers
      .searchResult || {};

  return (
    <FdrHolidayFinderProductsVoting
      initialOffers={(offers as Array<TFdrVotingCard>) || []}
      initialElementSetId={elementSetId}
    />
  );
}
