import React, {
  PropsWithChildren,
  ReactElement,
  useContext,
  useReducer,
} from 'react';
import { IFdrTravelDatesState } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import {
  fdrHolidayFinderDefaultState,
  fdrHolidayFinderReducer,
} from './fdr-holiday-finder.state';
import {
  FdrHolidayFinderActionType,
  FdrHolidayFinderPages,
  TFdrHolidayFinderState,
} from './fdr-holiday-finder.types';
import { useFdrHolidayFinderInitialState } from './useFdrHolidayFinderInitialState';

interface IFdrFdrHolidayFinderContext {
  state: TFdrHolidayFinderState;
  setChildren(withChildren: boolean): void;
  setValue(filter: string): void;
  setPage(nextPage: FdrHolidayFinderPages): void;
  setSessionId(sessionId: string): void;
  setTravelDates(travelDates: IFdrTravelDatesState): void;
  setToDefault(defaultState?: IFdrTravelDatesState): void;
}

const initialContext: IFdrFdrHolidayFinderContext = {
  state: fdrHolidayFinderDefaultState,
  setChildren: () => null,
  setValue: () => null,
  setPage: () => null,
  setSessionId: () => null,
  setTravelDates: () => null,
  setToDefault: () => null,
};

const FdrHolidayFinderContext =
  React.createContext<IFdrFdrHolidayFinderContext>(initialContext);

export function FdrHolidayFinderContextProvider({
  children,
}: PropsWithChildren): ReactElement {
  const { initialState } = useFdrHolidayFinderInitialState();
  const [state, dispatch] = useReducer(fdrHolidayFinderReducer, initialState);

  function setChildren(withChildren: boolean) {
    dispatch({
      type: FdrHolidayFinderActionType.SET_CHILDREN,
      withChildren,
    });
  }

  function setValue(filter: string) {
    dispatch({
      type: FdrHolidayFinderActionType.SET_VALUE,
      filter,
    });
  }

  function setPage(page: FdrHolidayFinderPages) {
    dispatch({
      type: FdrHolidayFinderActionType.SET_PAGE,
      page,
    });
  }

  function setTravelDates(travelDates: IFdrTravelDatesState) {
    dispatch({
      type: FdrHolidayFinderActionType.SET_TRAVEL_DATES,
      travelDates,
    });
  }

  function setSessionId(sessionId: string) {
    dispatch({
      type: FdrHolidayFinderActionType.SET_SESSION_ID,
      sessionId,
    });
  }

  function setToDefault() {
    dispatch({
      type: FdrHolidayFinderActionType.SET_STATE_TO_DEFAULT,
    });
  }

  const ctx: IFdrFdrHolidayFinderContext = {
    state,
    setChildren,
    setValue,
    setPage,
    setSessionId,
    setTravelDates,
    setToDefault,
  };

  return (
    <FdrHolidayFinderContext.Provider value={ctx}>
      {children}
    </FdrHolidayFinderContext.Provider>
  );
}

export function useFdrHolidayFinderContext(): IFdrFdrHolidayFinderContext {
  return useContext(FdrHolidayFinderContext);
}
