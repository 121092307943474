import dynamic from 'next/dynamic';
import React, { ReactElement, useRef, useState } from 'react';
import {
  CardsStack,
  IStackApi,
} from '@hotelplan/components.common.cards-stack';
import {
  FdrTravelDatesConfiguration,
  FdrTravelDatesControllers,
  FdrTravelDatesSkeletonDesktop,
  FdrTravelDatesSkeletonMobile,
  IFdrTravelDatesProps,
  useFdrTravelDatesState,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { trackFinderInit } from '@hotelplan/libs.tracking';
import { FdrHolidayFinderSwipingButtons } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder-buttons';
import { useFdrHolidayFinderContext } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.context';
import {
  FdrCardImage,
  FdrHolidayFinderVotingWrapper,
  FdrTravelDatesDesktopModal,
  FdrTravelDatesMobileModal,
} from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.styles';
import {
  FdrHolidayFinderPages,
  IFdrHolidayFinderSearchFormState,
} from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.types';
import { mapFdrHolidayFinderFormStateToTrackableData } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.utils';
import { FdrHolidayFinderFilterCard } from './fdr-holiday-finder-filter-card';
import { IFilterMockedCard, useFdrFilterCards } from './useFdrFilterCards';

const FdrTravelDatesMobile = dynamic<IFdrTravelDatesProps>(
  () =>
    import('@hotelplan/fdr.regular.fusion.fdr-travel-dates').then(
      module => module.FdrTravelDatesMobile
    ),
  {
    loading: function mobileSkeleton() {
      return <FdrTravelDatesSkeletonMobile />;
    },
  }
);

const FdrTravelDatesDesktop = dynamic<IFdrTravelDatesProps>(
  () =>
    import('@hotelplan/fdr.regular.fusion.fdr-travel-dates').then(
      module => module.FdrTravelDatesDesktop
    ),
  {
    loading: function desktopSkeleton() {
      return <FdrTravelDatesSkeletonDesktop />;
    },
  }
);

export function FdrHolidayFinderFiltersVoting(): ReactElement {
  const { mobile } = useDeviceType();

  const [showModalDates, setShowModalDates] = useState(false);

  const {
    state: fdrHolidayFinderState,
    setValue,
    setChildren,
    setTravelDates,
    setPage,
  } = useFdrHolidayFinderContext();

  const {
    withChildren,
    filters,
    travelDates: defaultTravelDates,
  } = fdrHolidayFinderState || {};

  const filterCards = useFdrFilterCards();
  const apiRef = useRef<IStackApi<IFilterMockedCard>>();

  function handleSwipeCard(
    card: IFilterMockedCard,
    direction: number,
    removeCardById: (filter: string) => void
  ) {
    const swipeRight = direction === 1;

    if (swipeRight) {
      if (card.name === 'filter') {
        if (card.giataId === 'children') {
          setChildren(swipeRight);
          return;
        }

        handleCustomCardsQueue(card.giataId, removeCardById);
        setValue(card.giataId);
      }

      if (card.name === 'modal') {
        setShowModalDates(true);
      }
    }
  }

  function handleFinishSwiping(
    card: IFilterMockedCard,
    direction: number,
    formState: IFdrHolidayFinderSearchFormState
  ) {
    const swipeRight = direction === 1;

    if (!swipeRight || card.name !== 'modal') {
      trackFinderInit(mapFdrHolidayFinderFormStateToTrackableData(formState));
      setPage(FdrHolidayFinderPages.PVotingPage);
    }
  }

  function closeTravelDatesModal(travelDates = defaultTravelDates) {
    trackFinderInit(
      mapFdrHolidayFinderFormStateToTrackableData({
        withChildren,
        travelDates,
        productFeatures: filters,
      })
    );
    setTravelDates(travelDates);
    setShowModalDates(false);
    setPage(FdrHolidayFinderPages.PVotingPage);
  }

  const {
    state: travelDatesState,
    setDepartureDate,
    setReturnDate,
    clearDateRange,
    setDuration,
    setSearchType,
  } = useFdrTravelDatesState({
    departureDate: defaultTravelDates?.departureDate,
    duration: defaultTravelDates?.duration,
    returnDate: defaultTravelDates?.returnDate,
    searchType: defaultTravelDates?.searchType,
    extraDurations: defaultTravelDates?.extraDurations,
    defaults: defaultTravelDates?.defaults,
  });

  const travelDatesConfiguration: FdrTravelDatesConfiguration = {
    hasApplyBtn: true,
    hasCustomWeekDayElement: mobile,
    hasNavbar: !mobile,
    numberOfMonthsToShow: mobile ? 13 : 2,
    canChangeMonth: !mobile,
  };

  return (
    <FdrHolidayFinderVotingWrapper>
      <CardsStack<IFilterMockedCard>
        stackApi={apiRef}
        initialStack={filterCards}
        getId={card => card?.giataId}
        renderCard={card => {
          if (card.name === 'modal') {
            return <FdrHolidayFinderFilterCard filterCard={card} />;
          }
          return <FdrCardImage image={card.image} />;
        }}
        onSwipe={({ card, direction }, { removeCardById }) => {
          handleSwipeCard(card, direction, removeCardById);
        }}
        onFinish={({ card, direction }) => {
          handleFinishSwiping(card, direction, {
            withChildren,
            travelDates: defaultTravelDates,
            productFeatures: filters,
          });
        }}
      />
      <FdrHolidayFinderSwipingButtons
        swipeLeft={() => apiRef.current?.swipeLeft()}
        swipeRight={() => apiRef.current?.swipeRight()}
      />
      {mobile ? (
        <FdrTravelDatesMobileModal
          fullScreen
          name="travelDates"
          label={`Label`}
          show={showModalDates}
          onClose={() => {
            closeTravelDatesModal();
          }}
        >
          <FdrTravelDatesMobile
            travelDatesState={travelDatesState}
            {...{
              setDepartureDate,
              setReturnDate,
              clearDateRange,
            }}
            configuration={travelDatesConfiguration}
            saveTravelDates={travelDates => {
              closeTravelDatesModal(travelDates);
            }}
          >
            <FdrTravelDatesControllers
              travelDatesState={travelDatesState}
              setSearchType={setSearchType}
              setDuration={setDuration}
              hasCustomWeekDayElement={true}
            />
          </FdrTravelDatesMobile>
        </FdrTravelDatesMobileModal>
      ) : (
        <FdrTravelDatesDesktopModal
          name="travelDates"
          label={`Label`}
          show={showModalDates}
          onClose={() => {
            closeTravelDatesModal();
          }}
        >
          <FdrTravelDatesDesktop
            travelDatesState={travelDatesState}
            initialReturnDate={defaultTravelDates?.returnDate}
            {...{
              setDepartureDate,
              setReturnDate,
              clearDateRange,
            }}
            configuration={{
              ...travelDatesConfiguration,
              initialMonth: defaultTravelDates?.departureDate,
            }}
            saveTravelDates={travelDates => {
              closeTravelDatesModal(travelDates);
            }}
          >
            <FdrTravelDatesControllers
              travelDatesState={travelDatesState}
              setSearchType={setSearchType}
              setDuration={setDuration}
              hasCustomWeekDayElement={false}
            />
          </FdrTravelDatesDesktop>
        </FdrTravelDatesDesktopModal>
      )}
    </FdrHolidayFinderVotingWrapper>
  );
}

function handleCustomCardsQueue(
  id: string,
  removeCardById: (filter: string) => void
) {
  if (id === 'beach-holidays') {
    removeCardById(`city-holidays`);
  }

  if (id === 'city-holidays') {
    removeCardById(`beauty-wellness`);
  }
}
