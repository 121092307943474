import React, { ReactElement } from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import {
  FdrFilterCardWrapper,
  FdrTextBlockWrapper,
} from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.styles';
import { IFilterMockedCard } from './useFdrFilterCards';

interface IFdrHolidayFinderFilterCardProps {
  filterCard: IFilterMockedCard;
}

export function FdrHolidayFinderFilterCard({
  filterCard,
}: IFdrHolidayFinderFilterCardProps): ReactElement {
  if (!filterCard) return null;

  const { image, icon, text } = filterCard;

  return (
    <FdrFilterCardWrapper>
      {image ? <FdrImage image={image} /> : null}
      {text || icon ? (
        <FdrTextBlockWrapper>
          {icon ? <BsIcon {...icon} /> : null}
          {text ? <span className="card-text">{text}</span> : null}
        </FdrTextBlockWrapper>
      ) : null}
    </FdrFilterCardWrapper>
  );
}
