import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-image.generated';
import { FdrResizedFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-resized.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHolidayFinderTeaserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrHolidayFinderTeaserQuery = {
  __typename?: 'Query';
  fdrHolidayFinder?: {
    __typename?: 'FdrHolidayFinderPages';
    landingPage?: {
      __typename?: 'FdrHolidayFinderLandingPage';
      hero?: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      } | null;
    } | null;
  } | null;
};

export const FdrHolidayFinderTeaserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHolidayFinderTeaser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHolidayFinder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hero' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrImage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resized' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'criteria' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '1x_desktop',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'mode',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'FILL_DOWN',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '855',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '540',
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '1x_mobile',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'mode',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'FILL_DOWN',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '570',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '375',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrResized' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHolidayFinderTeaserQuery__
 *
 * To run a query within a React component, call `useFdrHolidayFinderTeaserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHolidayFinderTeaserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHolidayFinderTeaserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrHolidayFinderTeaserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrHolidayFinderTeaserQuery,
    FdrHolidayFinderTeaserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrHolidayFinderTeaserQuery,
    FdrHolidayFinderTeaserQueryVariables
  >(FdrHolidayFinderTeaserDocument, options);
}
export function useFdrHolidayFinderTeaserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHolidayFinderTeaserQuery,
    FdrHolidayFinderTeaserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrHolidayFinderTeaserQuery,
    FdrHolidayFinderTeaserQueryVariables
  >(FdrHolidayFinderTeaserDocument, options);
}
export type FdrHolidayFinderTeaserQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderTeaserQuery
>;
export type FdrHolidayFinderTeaserLazyQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderTeaserLazyQuery
>;
export type FdrHolidayFinderTeaserQueryResult = Apollo.QueryResult<
  FdrHolidayFinderTeaserQuery,
  FdrHolidayFinderTeaserQueryVariables
>;
