import {
  FdrHolidayFinderPages,
  TFdrHolidayFinderAction,
  TFdrHolidayFinderState,
} from './fdr-holiday-finder.types';

export const fdrHolidayFinderDefaultState: TFdrHolidayFinderState = {
  filters: [],
  withChildren: false,
  travelDates: null,
  page: FdrHolidayFinderPages.LandingPage,
  sessionId: null,
};

export function fdrHolidayFinderReducer(
  state: TFdrHolidayFinderState,
  action: TFdrHolidayFinderAction
) {
  switch (action.type) {
    case 'ADD_FILTER': {
      if (!state.filters.includes(action.filter)) {
        return { ...state, filters: [...state.filters, action.filter] };
      }
      return { ...state };
    }
    case 'SET_TRAVEL_DATES': {
      return { ...state, travelDates: action.travelDates };
    }
    case 'SET_SESSION_ID': {
      return { ...state, sessionId: action.sessionId };
    }
    case 'SET_PAGE': {
      return { ...state, page: action.page };
    }
    case 'SET_CHILDREN': {
      return { ...state, withChildren: action.withChildren };
    }
    case 'SET_STATE_TO_DEFAULT': {
      return {
        ...(action.defaultState ?? fdrHolidayFinderDefaultState),
      };
    }
    default: {
      return;
    }
  }
}
