import { useTranslation } from 'next-i18next';
import { IBsIconProps } from '@hotelplan/core.basis.bs-icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  FdrImage as FdrImageType,
  FdrLinkType,
} from '@hotelplan/supergraph-api';

function randomFilterImage(images: Array<string>): string {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
}

export interface IFilterMockedCard {
  name: string;
  giataId: string;
  image: Omit<FdrImageType, 'id'>;
  icon?: {
    name: string;
    size: IBsIconProps['size'];
  };
  text?: string;
}

export function useFdrFilterCards(): IFilterMockedCard[] {
  const {
    beachHolidays,
    cityHolidays,
    beautyWellness,
    nightlife,
    children,
    travelPeriod,
    shopping,
  } = useMockedFilterCards();

  const [t] = useTranslation();

  return [
    {
      name: `filter`,
      giataId: `beach-holidays`,
      image: {
        alt: 'beach-holidays',
        rawUrl: randomFilterImage(beachHolidays),
        resized: [
          {
            id: '',
            link: {
              type: FdrLinkType.Internal,
              url: randomFilterImage(beachHolidays),
            },
          },
        ],
      },
    },
    {
      name: `filter`,
      giataId: `children`,
      image: {
        alt: 'children',
        rawUrl: randomFilterImage(children),
        resized: [
          {
            id: '',
            link: {
              type: FdrLinkType.Internal,
              url: randomFilterImage(children),
            },
          },
        ],
      },
    },
    {
      name: `filter`,
      giataId: `city-holidays`,
      image: {
        alt: 'city-holidays',
        rawUrl: randomFilterImage(cityHolidays),
        resized: [
          {
            id: '',
            link: {
              type: FdrLinkType.Internal,
              url: randomFilterImage(cityHolidays),
            },
          },
        ],
      },
    },
    {
      name: `filter`,
      giataId: `nightlife`,
      image: {
        alt: 'nightlife',
        rawUrl: randomFilterImage(nightlife),
        resized: [
          {
            id: '',
            link: {
              type: FdrLinkType.Internal,
              url: randomFilterImage(nightlife),
            },
          },
        ],
      },
    },
    {
      name: `filter`,
      giataId: `shopping`,
      image: {
        alt: 'shopping',
        rawUrl: randomFilterImage(shopping),
        resized: [
          {
            id: '',
            link: {
              type: FdrLinkType.Internal,
              url: randomFilterImage(shopping),
            },
          },
        ],
      },
    },
    {
      name: `filter`,
      giataId: `beauty-wellness`,
      image: {
        alt: 'beauty-wellness',
        rawUrl: randomFilterImage(beautyWellness),
        resized: [
          {
            id: '',
            link: {
              type: FdrLinkType.Internal,
              url: randomFilterImage(beautyWellness),
            },
          },
        ],
      },
    },
    {
      name: `modal`,
      giataId: `calendar`,
      text: t('holidayfinder:calendar.text'),
      icon: {
        name: `calendar`,
        size: 'md',
      },
      image: {
        alt: 'Beach-card',
        rawUrl: travelPeriod[0],
        resized: [
          {
            id: '',
            link: {
              type: FdrLinkType.Internal,
              url: travelPeriod[0],
            },
          },
        ],
      },
    },
  ];
}

function useMockedFilterCards() {
  const { mobile } = useDeviceType();

  return {
    beachHolidays: [
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/beach_1@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/beach_2@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/beach_3@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/beach_4@2x.jpg`,
    ],
    children: [
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/children_1@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/children_2@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/children_3@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/children_4@2x.jpg`,
    ],
    nightlife: [
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/Nightlife_1@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/Nightlife_2@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/Nightlife_3@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/Nightlife_4@2x.jpg`,
    ],
    shopping: [
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/shopping_1@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/shopping_2@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/shopping_3@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/shopping_4@2x.jpg`,
    ],
    beautyWellness: [
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/BeautyWellness_1@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/BeautyWellness_2@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/BeautyWellness_3@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/BeautyWellness_4@2x.jpg`,
    ],
    cityHolidays: [
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/city_1@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/city_2@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/city_3@2x.jpg`,
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/city_4@2x.jpg`,
    ],
    travelPeriod: [
      `images/finder/custom-types/${
        mobile ? 'mobile' : 'desktop'
      }/travelPeriod@2x.jpg`,
    ],
  };
}

interface IFdrFunctionButtons {
  allToWishlistImage: Omit<FdrImageType, 'id'>;
  newStartImage: Omit<FdrImageType, 'id'>;
}

export function useFdrFunctionButtons(): IFdrFunctionButtons {
  const allToWishlistImage: Omit<FdrImageType, 'id'> = {
    alt: 'all-to-wishlist',
    desaturated: true,
    rawUrl: `images/finder/all-to-wishlist-bg.jpg`,
    resized: [
      {
        id: '',
        link: {
          type: FdrLinkType.Internal,
          url: `images/finder/all-to-wishlist-bg.jpg`,
        },
      },
    ],
  };

  const newStartImage: Omit<FdrImageType, 'id'> = {
    alt: 'all-to-wishlist',
    desaturated: true,
    rawUrl: `images/finder/newstart-bg.jpg`,
    resized: [
      {
        id: '',
        link: {
          type: FdrLinkType.Internal,
          url: `images/finder/newstart-bg.jpg`,
        },
      },
    ],
  };

  return {
    allToWishlistImage,
    newStartImage,
  };
}
