import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useMemoryPagination } from '@hotelplan/libs.hooks';
import {
  trackFinderCardClick,
  trackFinderWishlist,
  WishlistAction,
} from '@hotelplan/libs.tracking';
import { formatDate, parseDateStr } from '@hotelplan/libs.utils';
import {
  FdrImage,
  FdrLink,
  FdrLinkOpenMethod,
  FdrProductTravelType,
} from '@hotelplan/supergraph-api';
import Container from 'components/domain/container/Container';
import WishlistAddFormContainer from 'components/domain/wishlist/WishlistAddFormContainer';
import { fdrHolidayFinderVars } from 'components/page/holiday-finder/holiday-finder.vars';
import useAddMultipleWishlistOffers from 'fdr/components/candidate/fdr-wishlist/fdr-use-add-multiple-wishlist-offers';
import { FdrFooter } from 'fdr/components/local/fdr-footer';
import {
  FdrOfferBreadcrumbs,
  FdrOfferDatesInformation,
} from 'fdr/components/local/fdr-offer';
import { fdrSmallProductCardStyles } from 'fdr/components/local/fdr-page-components/recommendations/fdr-recommendations.styles';
import { FdrProductFavContainer } from 'fdr/components/local/fdr-page-components/recommendations/product/fdr-product-recommendation.styles';
import { FdrProductCard } from 'fdr/components/local/fdr-product-card/fdr-product-card';
import { FdrShowMoreButton } from 'fdr/components/local/fdr-recommendation-list/fdr-recommendation-list.styles';
import { FdrStyledTextBlock } from 'fdr/components/local/fdr-styled-text-block';
import { WishlistSource } from 'fdr/components/local/fdr-wishlist/fdr-wishlist.constants';
import { FdrFeatureGroupsFragment } from 'fdr/schemas/fragment/feature-groups/fdr-feature-groups.generated';
import { FdrHolidayFinderOfferFragment } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-initial-offers.generated';
import { FdrHolidayFinderProductOfferFragment } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-next-offer.generated';
import { useFdrHolidayFinderResultsPageQuery } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-results-page.generated';
import { FdrHolidayFinderSkeleton } from './fdr-holida-finder-results.skeleton';
import { FdrAddAllToWishlistButton } from './fdr-holiday-finder-buttons';
import { FdrHolidayFinderFunctionCards } from './fdr-holiday-finder-function-cards';
import { FdrHolidayFinderInfoOverlay } from './fdr-holiday-finder-info-overlay';
import { FdrHolidayFinderResultsInfo } from './fdr-holiday-finder-results-info';
import { FdrHolidayFinderResultsTextSkeleton } from './fdr-holiday-finder-results-text.skeleton';
import {
  FdrHolidayFinderItemWrap,
  FdrHolidayFinderResultsDetails,
  FdrHolidayFinderResultsInfoWrapper,
  FdrHolidayFinderResultsSection,
  FdrHolidayFinderResultsSectionWrapper,
  FdrHolidayFinderResultsTextBlock,
  FdrHolidayFinderResultsWrap,
  FdrOfferTypesAndMealType,
} from './fdr-holiday-finder.styles';
import { mapFdrHolidayFinderProductToTrackableData } from './fdr-holiday-finder.utils';

const ITEMS_PER_PAGE = 6;

interface IFdrHolidayFinderResultListProps {
  offers: Array<FdrHolidayFinderProductOfferFragment>;
  loading?: boolean;
}

export function FdrHolidayFinderResultList({
  offers,
  loading: offersLoading,
}: IFdrHolidayFinderResultListProps): ReactElement {
  const [t] = useTranslation(['common', 'wishlist', 'holidayfinder']);
  const { channelType } = useAuthentication();

  const { data: staticData, loading: staticDataLoading } =
    useFdrHolidayFinderResultsPageQuery({
      variables: fdrHolidayFinderVars,
    });

  const { items, page, nextPage, hasMore } =
    useMemoryPagination<FdrHolidayFinderProductOfferFragment>(offers, {
      perPage: ITEMS_PER_PAGE,
      enable: !!offers?.length,
    });

  const { onAddMultiple, loading: addMultipleLoading } =
    useAddMultipleWishlistOffers();

  async function onBulkAddToWishlist() {
    const allOfferIds = offers.map(offer => ({
      offerId: offer.id,
      source: WishlistSource.HF, // todo: replace to FdrWishlistSource
    }));

    const trackableCards = offers.map(offer =>
      mapFdrHolidayFinderProductToTrackableData(offer)
    );

    trackFinderWishlist(WishlistAction.add, trackableCards);
    await onAddMultiple(allOfferIds);
  }

  function checkIsWishlist(el) {
    const closestWishlist = el.closest(`.wishlist-icon`);
    const notActive =
      closestWishlist && closestWishlist.className.includes('not-active');

    return {
      isWishlist: !!closestWishlist,
      isActive: !notActive,
    };
  }

  function handleOfferClick(
    e: React.MouseEvent,
    offer: FdrHolidayFinderProductOfferFragment
  ) {
    const { isWishlist, isActive } = checkIsWishlist(e.target);

    if (isWishlist) {
      trackFinderWishlist(
        isActive ? WishlistAction.remove : WishlistAction.add,
        [mapFdrHolidayFinderProductToTrackableData(offer)]
      );
    } else {
      trackFinderCardClick(mapFdrHolidayFinderProductToTrackableData(offer));
    }
  }

  const loading = offersLoading || staticDataLoading;
  const { text, textFgColor, textBgColor, title, textBgImage } =
    staticData?.fdrHolidayFinder?.resultPage || {};

  return (
    <>
      <FdrHolidayFinderResultsSection>
        <FdrHolidayFinderInfoOverlay />
        <Container>
          <FdrHolidayFinderResultsTextBlock>
            {loading ? (
              <FdrHolidayFinderResultsTextSkeleton />
            ) : (
              <FdrStyledTextBlock
                text={text}
                title={title}
                fontColor={textFgColor}
                backgroundColor={textBgColor}
                backgroundImage={textBgImage}
                className="holiday-finder-text-block-wrapper"
              />
            )}
          </FdrHolidayFinderResultsTextBlock>
          <FdrHolidayFinderResultsDetails>
            {channelType !== AuthChannelType.B2CAnonymous && (
              <FdrHolidayFinderResultsSectionWrapper>
                {/* todo: replace with fdr version */}
                <WishlistAddFormContainer
                  toggleBtnText={t('holidayfinder:wishlistForm.toggle.button')}
                  onSubmit={onBulkAddToWishlist}
                />
              </FdrHolidayFinderResultsSectionWrapper>
            )}
            {offers.length > 0 && (
              <FdrHolidayFinderResultsInfoWrapper>
                <FdrHolidayFinderResultsInfo
                  productCount={offers.length}
                  lowestPrice={{
                    value: Math.min(
                      ...offers.map(offer => offer.pricePerPerson.value)
                    ),
                    currency: offers.find(
                      offer => offer.pricePerPerson.currency
                    ).pricePerPerson.currency,
                  }}
                  loading={loading}
                />

                <FdrAddAllToWishlistButton
                  onAddToWishlist={onBulkAddToWishlist}
                  loading={addMultipleLoading}
                />
              </FdrHolidayFinderResultsInfoWrapper>
            )}
          </FdrHolidayFinderResultsDetails>

          {loading ? (
            <FdrHolidayFinderResultsWrap>
              <FdrHolidayFinderSkeleton />
            </FdrHolidayFinderResultsWrap>
          ) : (
            <FdrHolidayFinderResultsWrap>
              {items.map((offer, index) => {
                return (
                  <FdrHolidayFinderItemWrap
                    key={`product-${index}`}
                    onClick={e => handleOfferClick(e, offer)}
                  >
                    <FdrHolidayFinderProductCard offer={offer} />
                  </FdrHolidayFinderItemWrap>
                );
              })}
              {hasMore || page ? (
                <section className="pagination-buttons">
                  {hasMore ? (
                    <FdrShowMoreButton onClick={nextPage}>
                      {t('common:moreOffers.button')}
                      <BsIcon name="chevron-down" />
                    </FdrShowMoreButton>
                  ) : null}
                </section>
              ) : null}
            </FdrHolidayFinderResultsWrap>
          )}
          <FdrHolidayFinderFunctionCards
            onAddToWishlist={onBulkAddToWishlist}
            loading={addMultipleLoading}
          />
        </Container>
      </FdrHolidayFinderResultsSection>
      <FdrFooter />
    </>
  );
}

const FinderProductItem = styled(FdrProductCard)(fdrSmallProductCardStyles);

interface IFdrHolidayFinderProductCardProps {
  offer: FdrHolidayFinderProductOfferFragment;
}

function FdrHolidayFinderProductCard({
  offer,
}: IFdrHolidayFinderProductCardProps): ReactElement {
  const [t] = useTranslation();

  const {
    id: productId,
    name,
    image,
    link,
    isInWishlist,
    rating,
    productFeatures,
    isRoundtripOrCruise,
    mealType,
    breadcrumbs,
  } = getProductContent(offer.product) || {};

  const packageTT = { icon: 'flug', title: 'common:flight' };
  const hotelTT = { icon: 'hotel', title: 'common:hotel' };

  const productTravelType =
    offer.travelType === FdrProductTravelType.Package
      ? [packageTT, hotelTT]
      : [hotelTT];

  return (
    <FdrProductFavContainer
      offerId={offer.id}
      productId={productId}
      inWishlist={isInWishlist}
      className="product-fav-container"
    >
      <FinderProductItem
        openMethod={FdrLinkOpenMethod.ThisTab}
        image={image}
        price={offer.pricePerPerson}
        link={link}
        title={name}
        rating={rating}
        productFeatures={productFeatures}
        productTravelType={productTravelType}
        subTitle={
          <FdrOfferBreadcrumbs
            breadcrumbs={breadcrumbs.map(br => ({ caption: br }))}
          />
        }
      >
        <>
          {offer.departureDate && offer.returnDate && offer.duration ? (
            <FdrOfferDatesInformation
              departureDate={formatDate(
                parseDateStr(offer.departureDate),
                'dd.MM.yyyy'
              )}
              returnDate={formatDate(
                parseDateStr(offer.returnDate),
                'dd.MM.yyyy'
              )}
              duration={t('common:nights.count', {
                count: parseInt(offer.duration.toString(), 10),
              })}
            />
          ) : null}
          {!isRoundtripOrCruise && (
            <FdrOfferTypesAndMealType>
              {productTravelType?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {t(item.title)}
                    {productTravelType.length - 1 !== i && <span> + </span>}
                  </React.Fragment>
                );
              })}
              {mealType && <span>, {mealType}</span>}
            </FdrOfferTypesAndMealType>
          )}
        </>
      </FinderProductItem>
    </FdrProductFavContainer>
  );
}

interface IProductContent {
  id: string;
  name: string;
  image: Omit<FdrImage, 'id'>;
  link: FdrLink;
  isInWishlist: boolean;
  rating: number;
  mealType?: string;
  productFeatures: Array<FdrFeatureGroupsFragment>;
  isRoundtripOrCruise?: boolean;
  breadcrumbs: Array<string>;
}

function getProductContent(
  product: FdrHolidayFinderOfferFragment['product']
): IProductContent {
  if (!product) return null;

  switch (product.__typename) {
    case 'FdrHotel': {
      const { resort } = product;
      const { name: resortName, destination } = resort || {};

      return {
        id: product.id,
        name: product.name,
        image: product.hero,
        link: product.webMeta.descriptionWebMeta?.link,
        isInWishlist: product.isInBookmarks,
        rating: product.rating,
        productFeatures: product.summaryFeatureGroups,
        isRoundtripOrCruise: false,
        breadcrumbs: [
          destination.country.name,
          destination.name,
          resortName,
        ].filter(Boolean),
      };
    }
    default:
      return null;
  }
}
