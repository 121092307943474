import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPriceFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-price.generated';
import { FdrHotelHolidayFinderOfferFragmentDoc } from 'fdr/schemas/fragment/product/fdr-hotel-holiday-finder-offer.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHolidayFinderNextOfferQueryVariables = Types.Exact<{
  input: Types.FdrHolidayFinderNextOffersInput;
}>;

export type FdrHolidayFinderNextOfferQuery = {
  __typename?: 'Query';
  fdrHolidayFinder?: {
    __typename?: 'FdrHolidayFinderPages';
    votingPage?: {
      __typename?: 'FdrHolidayFinderVotingPage';
      nextOffers: {
        __typename?: 'FdrHolidayFinderNextOffersResponse';
        searchResult: {
          __typename?: 'FdrHolidayFinderOffersSearchResult';
          ptoken: string;
          elementSetId: string;
          continueVoting: boolean;
          votesCollected: number;
          message?: string | null;
          productOffers?: Array<{
            __typename?: 'FdrProductOffer';
            id: string;
            travelType: Types.FdrProductTravelType;
            duration: number;
            departureDate: any;
            returnDate: any;
            pricePerPerson: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            };
            product?:
              | { __typename?: 'FdrCruise' }
              | {
                  __typename?: 'FdrHotel';
                  giataCode?: number | null;
                  isInBookmarks: boolean;
                  name: string;
                  rating: number;
                  id: string;
                  hubData?: {
                    __typename?: 'FdrHubProductData';
                    taRating: number;
                    taReviews: number;
                  } | null;
                  summaryFeatureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      id: string;
                      name: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                  hero?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  webMeta: {
                    __typename?: 'FdrProductWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      title: string;
                      description: string;
                      index: boolean;
                      noFollow: boolean;
                      sitemap: boolean;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  resort: {
                    __typename?: 'FdrResort';
                    name: string;
                    webMeta: {
                      __typename?: 'FdrGeoWebMeta';
                      descriptionWebMeta?: {
                        __typename?: 'FdrMeta';
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      } | null;
                    };
                    destination: {
                      __typename?: 'FdrDestination';
                      name: string;
                      webMeta: {
                        __typename?: 'FdrGeoWebMeta';
                        descriptionWebMeta?: {
                          __typename?: 'FdrMeta';
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        } | null;
                      };
                      region?: {
                        __typename?: 'FdrRegion';
                        name: string;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          descriptionWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                      } | null;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          descriptionWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                        countryGroup?: {
                          __typename?: 'FdrCountryGroup';
                          name: string;
                          webMeta: {
                            __typename?: 'FdrGeoWebMeta';
                            descriptionWebMeta?: {
                              __typename?: 'FdrMeta';
                              link: {
                                __typename?: 'FdrLink';
                                openMethod?: Types.FdrLinkOpenMethod | null;
                                legacyUrl?: string | null;
                                type: Types.FdrLinkType;
                                url: string;
                              };
                            } | null;
                          };
                        } | null;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          webMeta: {
                            __typename?: 'FdrGeoWebMeta';
                            descriptionWebMeta?: {
                              __typename?: 'FdrMeta';
                              link: {
                                __typename?: 'FdrLink';
                                openMethod?: Types.FdrLinkOpenMethod | null;
                                legacyUrl?: string | null;
                                type: Types.FdrLinkType;
                                url: string;
                              };
                            } | null;
                          };
                        };
                      };
                    };
                  };
                }
              | { __typename?: 'FdrRoundTrip' }
              | { __typename?: 'FdrAdventureTravel' }
              | null;
          }> | null;
        };
      };
    } | null;
  } | null;
};

export type FdrHolidayFinderProductOfferFragment = {
  __typename?: 'FdrProductOffer';
  id: string;
  travelType: Types.FdrProductTravelType;
  duration: number;
  departureDate: any;
  returnDate: any;
  pricePerPerson: { __typename?: 'FdrPrice'; value: number; currency: string };
  product?:
    | { __typename?: 'FdrCruise' }
    | {
        __typename?: 'FdrHotel';
        giataCode?: number | null;
        isInBookmarks: boolean;
        name: string;
        rating: number;
        id: string;
        hubData?: {
          __typename?: 'FdrHubProductData';
          taRating: number;
          taReviews: number;
        } | null;
        summaryFeatureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            id: string;
            name: string;
            enabled: boolean;
          }>;
        }> | null;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            title: string;
            description: string;
            index: boolean;
            noFollow: boolean;
            sitemap: boolean;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
        resort: {
          __typename?: 'FdrResort';
          name: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
            region?: {
              __typename?: 'FdrRegion';
              name: string;
              webMeta: {
                __typename?: 'FdrGeoWebMeta';
                descriptionWebMeta?: {
                  __typename?: 'FdrMeta';
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                } | null;
              };
            } | null;
            country: {
              __typename?: 'FdrCountry';
              name: string;
              webMeta: {
                __typename?: 'FdrGeoWebMeta';
                descriptionWebMeta?: {
                  __typename?: 'FdrMeta';
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                } | null;
              };
              countryGroup?: {
                __typename?: 'FdrCountryGroup';
                name: string;
                webMeta: {
                  __typename?: 'FdrGeoWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
              } | null;
              continent: {
                __typename?: 'FdrContinent';
                name: string;
                webMeta: {
                  __typename?: 'FdrGeoWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
              };
            };
          };
        };
      }
    | { __typename?: 'FdrRoundTrip' }
    | { __typename?: 'FdrAdventureTravel' }
    | null;
};

export const FdrHolidayFinderProductOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHolidayFinderProductOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrHotel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrHotelHolidayFinderOffer',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPriceFragmentDoc.definitions,
    ...FdrHotelHolidayFinderOfferFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHolidayFinderNextOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHolidayFinderNextOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrHolidayFinderNextOffersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHolidayFinder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'votingPage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextOffers' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'input' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'searchResult' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ptoken' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'elementSetId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'continueVoting',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'votesCollected',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productOffers',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'fdrHolidayFinderProductOffer',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHolidayFinderProductOfferFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHolidayFinderNextOfferQuery__
 *
 * To run a query within a React component, call `useFdrHolidayFinderNextOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHolidayFinderNextOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHolidayFinderNextOfferQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrHolidayFinderNextOfferQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrHolidayFinderNextOfferQuery,
    FdrHolidayFinderNextOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrHolidayFinderNextOfferQuery,
    FdrHolidayFinderNextOfferQueryVariables
  >(FdrHolidayFinderNextOfferDocument, options);
}
export function useFdrHolidayFinderNextOfferLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHolidayFinderNextOfferQuery,
    FdrHolidayFinderNextOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrHolidayFinderNextOfferQuery,
    FdrHolidayFinderNextOfferQueryVariables
  >(FdrHolidayFinderNextOfferDocument, options);
}
export type FdrHolidayFinderNextOfferQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderNextOfferQuery
>;
export type FdrHolidayFinderNextOfferLazyQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderNextOfferLazyQuery
>;
export type FdrHolidayFinderNextOfferQueryResult = Apollo.QueryResult<
  FdrHolidayFinderNextOfferQuery,
  FdrHolidayFinderNextOfferQueryVariables
>;
