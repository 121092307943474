import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { trackFinderRestart } from '@hotelplan/libs.tracking';
import { FdrAddAllToWishlistButton } from './fdr-holiday-finder-buttons';
import { useFdrHolidayFinderContext } from './fdr-holiday-finder.context';
import {
  FdrFunctionButton,
  FdrFunctionButtonImage,
  FdrFunctionCardContent,
  FdrFunctionCardWrapper,
  FdrHolidayFinderFunctionCardsWrapper,
} from './fdr-holiday-finder.styles';
import { FdrHolidayFinderPages } from './fdr-holiday-finder.types';
import { useFdrFunctionButtons } from './voting/useFdrFilterCards';

interface IFdrHolidayFinderFunctionCardsProps {
  onAddToWishlist(): void;
  loading: boolean;
}

export function FdrHolidayFinderFunctionCards({
  onAddToWishlist,
  loading,
}: IFdrHolidayFinderFunctionCardsProps): ReactElement {
  const [t] = useTranslation('holidayfinder');

  const { allToWishlistImage, newStartImage } = useFdrFunctionButtons();
  const { setPage, setToDefault } = useFdrHolidayFinderContext();

  function handleFunctionButtonClick() {
    setToDefault();
    setPage(FdrHolidayFinderPages.LandingPage);
    trackFinderRestart();
  }

  return (
    <FdrHolidayFinderFunctionCardsWrapper>
      <FdrFunctionCardWrapper>
        <FdrFunctionCardContent>
          <p className="function-button-text">
            {t('booklater.question')} <br /> {t('booklater.text')}
          </p>
          <FdrAddAllToWishlistButton
            onAddToWishlist={onAddToWishlist}
            loading={loading}
          />
        </FdrFunctionCardContent>
        <FdrFunctionButtonImage image={allToWishlistImage} />
      </FdrFunctionCardWrapper>
      <FdrFunctionCardWrapper>
        <FdrFunctionCardContent>
          <p className="function-button-text restart-text">
            {t('nothingThere.question')} <br /> {t('nothingThere.text')}
          </p>
          <FdrFunctionButton onClick={handleFunctionButtonClick}>
            {t('play.button')}
          </FdrFunctionButton>
        </FdrFunctionCardContent>
        <FdrFunctionButtonImage image={newStartImage} />
      </FdrFunctionCardWrapper>
    </FdrHolidayFinderFunctionCardsWrapper>
  );
}
