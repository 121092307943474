import {
  FdrHolidayFinderPages,
  TFdrHolidayFinderState,
} from './fdr-holiday-finder.types';
import { useFdrHolidayFinderUrlSearchState } from './useFdrHolidayFinderUrlSearchState';

interface IHolidayFinderInitialState {
  initialState: TFdrHolidayFinderState;
}

export function useFdrHolidayFinderInitialState(): IHolidayFinderInitialState {
  const { giataIds, travelDates } = useFdrHolidayFinderUrlSearchState();

  const initialState: TFdrHolidayFinderState = {
    filters: [],
    withChildren: false,
    travelDates: giataIds.length ? travelDates : null,
    page: giataIds.length
      ? FdrHolidayFinderPages.SearchResultPage
      : FdrHolidayFinderPages.LandingPage,
    sessionId: null,
  };

  return {
    initialState,
  };
}
