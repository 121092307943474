import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

export function FdrHolidayFinderResultsTextSkeleton(): ReactElement {
  const { mobile } = useDeviceType();

  return (
    <ContentLoader
      speed={2}
      width={mobile ? 322 : 425}
      height={mobile ? 86 : 66}
      style={{
        width: '100%',
        height: mobile ? '86px' : '66px',
      }}
    >
      {mobile ? (
        <>
          <rect y="66" width="322" height="20" rx="10" />
          <rect width="299" height="25" rx="10" />
          <rect y="29" width="232" height="25" rx="10" />
        </>
      ) : (
        <>
          <rect y="46" width="365" height="20" rx="10" />
          <rect width="425" height="29" rx="10" />
        </>
      )}
    </ContentLoader>
  );
}
