import {
  query2FdrCriteria,
  TFdrMediaMeta,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { lCfg, pCfg } from 'fdr/components/util/criterias/base.dmc';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';

export const fdrTextBgMobileImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`383w_mobile`, pCfg(383, 324, FdrImageResizeMode.FillDown)],
  [`912w_mobile`, pCfg(769, 648, FdrImageResizeMode.FillDown)],
];

const fdrTextBgDesktopImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`1142w_desktop`, lCfg(1142, 383, FdrImageResizeMode.FillDown)],
  [`2284w_desktop`, lCfg(2284, 766, FdrImageResizeMode.FillDown)],
];

export const fdrTextBgImagesSpecs: TFdrMediaMetaMap = new Map([
  ...fdrTextBgMobileImagesSpecs,
  ...fdrTextBgDesktopImagesSpecs,
]);

export const fdrTextBgCriteria = query2FdrCriteria(fdrTextBgImagesSpecs);
