import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrHolidayFinderProductOfferFragmentDoc } from './fdr-holiday-finder-next-offer.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHolidayFinderVotingResultsQueryVariables = Types.Exact<{
  input: Types.FdrHolidayFinderResultOffersInput;
}>;

export type FdrHolidayFinderVotingResultsQuery = {
  __typename?: 'Query';
  fdrHolidayFinder?: {
    __typename?: 'FdrHolidayFinderPages';
    resultPage?: {
      __typename?: 'FdrHolidayFinderResultPage';
      resultOffers: {
        __typename?: 'FdrHolidayFinderResultOffersResponse';
        searchResult: {
          __typename?: 'FdrHolidayFinderOffersSearchResult';
          votesCollected: number;
          elementSetId: string;
          ptoken: string;
          productOffers?: Array<{
            __typename?: 'FdrProductOffer';
            id: string;
            travelType: Types.FdrProductTravelType;
            duration: number;
            departureDate: any;
            returnDate: any;
            pricePerPerson: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            };
            product?:
              | { __typename?: 'FdrCruise' }
              | {
                  __typename?: 'FdrHotel';
                  giataCode?: number | null;
                  isInBookmarks: boolean;
                  name: string;
                  rating: number;
                  id: string;
                  hubData?: {
                    __typename?: 'FdrHubProductData';
                    taRating: number;
                    taReviews: number;
                  } | null;
                  summaryFeatureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      id: string;
                      name: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                  hero?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  webMeta: {
                    __typename?: 'FdrProductWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      title: string;
                      description: string;
                      index: boolean;
                      noFollow: boolean;
                      sitemap: boolean;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  resort: {
                    __typename?: 'FdrResort';
                    name: string;
                    webMeta: {
                      __typename?: 'FdrGeoWebMeta';
                      descriptionWebMeta?: {
                        __typename?: 'FdrMeta';
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      } | null;
                    };
                    destination: {
                      __typename?: 'FdrDestination';
                      name: string;
                      webMeta: {
                        __typename?: 'FdrGeoWebMeta';
                        descriptionWebMeta?: {
                          __typename?: 'FdrMeta';
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        } | null;
                      };
                      region?: {
                        __typename?: 'FdrRegion';
                        name: string;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          descriptionWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                      } | null;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          descriptionWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                        countryGroup?: {
                          __typename?: 'FdrCountryGroup';
                          name: string;
                          webMeta: {
                            __typename?: 'FdrGeoWebMeta';
                            descriptionWebMeta?: {
                              __typename?: 'FdrMeta';
                              link: {
                                __typename?: 'FdrLink';
                                openMethod?: Types.FdrLinkOpenMethod | null;
                                legacyUrl?: string | null;
                                type: Types.FdrLinkType;
                                url: string;
                              };
                            } | null;
                          };
                        } | null;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          webMeta: {
                            __typename?: 'FdrGeoWebMeta';
                            descriptionWebMeta?: {
                              __typename?: 'FdrMeta';
                              link: {
                                __typename?: 'FdrLink';
                                openMethod?: Types.FdrLinkOpenMethod | null;
                                legacyUrl?: string | null;
                                type: Types.FdrLinkType;
                                url: string;
                              };
                            } | null;
                          };
                        };
                      };
                    };
                  };
                }
              | { __typename?: 'FdrRoundTrip' }
              | { __typename?: 'FdrAdventureTravel' }
              | null;
          }> | null;
        };
      };
    } | null;
  } | null;
};

export const FdrHolidayFinderVotingResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHolidayFinderVotingResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'FdrHolidayFinderResultOffersInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHolidayFinder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultPage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resultOffers' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'input' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'searchResult' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'votesCollected',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'elementSetId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ptoken' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productOffers',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'fdrHolidayFinderProductOffer',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHolidayFinderProductOfferFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHolidayFinderVotingResultsQuery__
 *
 * To run a query within a React component, call `useFdrHolidayFinderVotingResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHolidayFinderVotingResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHolidayFinderVotingResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrHolidayFinderVotingResultsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrHolidayFinderVotingResultsQuery,
    FdrHolidayFinderVotingResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrHolidayFinderVotingResultsQuery,
    FdrHolidayFinderVotingResultsQueryVariables
  >(FdrHolidayFinderVotingResultsDocument, options);
}
export function useFdrHolidayFinderVotingResultsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHolidayFinderVotingResultsQuery,
    FdrHolidayFinderVotingResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrHolidayFinderVotingResultsQuery,
    FdrHolidayFinderVotingResultsQueryVariables
  >(FdrHolidayFinderVotingResultsDocument, options);
}
export type FdrHolidayFinderVotingResultsQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderVotingResultsQuery
>;
export type FdrHolidayFinderVotingResultsLazyQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderVotingResultsLazyQuery
>;
export type FdrHolidayFinderVotingResultsQueryResult = Apollo.QueryResult<
  FdrHolidayFinderVotingResultsQuery,
  FdrHolidayFinderVotingResultsQueryVariables
>;
