import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { formatPrice } from '@hotelplan/libs.utils';
import { FdrPrice } from '@hotelplan/supergraph-api';
import { FdrHolidayFinderResultsInfoSkeleton } from './fdr-holiday-finder-results-info.skeleton';
import { FdrResultsInfoWrapper } from './fdr-holiday-finder.styles';
import { mapPrice } from './fdr-holiday-finder.utils';

interface IFdrHolidayFinderResultsInfoProps {
  productCount: number;
  lowestPrice?: FdrPrice;
  loading?: boolean;
}

export function FdrHolidayFinderResultsInfo({
  productCount,
  lowestPrice,
  loading,
}: IFdrHolidayFinderResultsInfoProps): ReactElement {
  const [t] = useTranslation('holidayfinder');

  return (
    <FdrResultsInfoWrapper>
      {loading ? (
        <FdrHolidayFinderResultsInfoSkeleton />
      ) : (
        <>
          <span data-id="products-count" className="products-count">
            {productCount}
          </span>{' '}
          <span>{t('pricePrefix.text')}</span>{' '}
          <span data-id="lowest-price">
            {formatPrice(mapPrice(lowestPrice))}
          </span>
        </>
      )}
    </FdrResultsInfoWrapper>
  );
}
