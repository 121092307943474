import React, { ReactElement, useRef } from 'react';
import {
  CardsStack,
  IStackApi,
} from '@hotelplan/components.common.cards-stack';
import { FdrHolidayFinderSwipingButtons } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder-buttons';
import { FdrHolidayFinderVotingWrapper } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.styles';
import { FdrHotelHolidayFinderOfferFragment } from 'fdr/schemas/fragment/product/fdr-hotel-holiday-finder-offer.generated';
import { FdrHolidayFinderProductCard } from './fdr-holiday-finder-product-card';
import { TFdrVotingCard } from './fdr-voting.types';
import { useFdrHolidayFinderVote } from './useFdrHolidayFinderVote';

interface IFdrHolidayFinderProductsVotingProps {
  initialOffers: Array<TFdrVotingCard>;
  initialElementSetId: string;
}

export function FdrHolidayFinderProductsVoting({
  initialOffers,
  initialElementSetId,
}: IFdrHolidayFinderProductsVotingProps): ReactElement {
  const apiRef = useRef<IStackApi<TFdrVotingCard>>();

  const { swipe } = useFdrHolidayFinderVote({
    initialElementSetId,
  });

  return (
    <FdrHolidayFinderVotingWrapper>
      <CardsStack<TFdrVotingCard>
        stackApi={apiRef}
        initialStack={[...initialOffers].filter(Boolean)}
        getId={card =>
          (
            card?.product as FdrHotelHolidayFinderOfferFragment
          )?.giataCode?.toString()
        }
        renderCard={card => <FdrHolidayFinderProductCard offer={card} />}
        onSwipe={({ card, direction }, { addCard }) => {
          swipe(card, direction, addCard);
        }}
      />
      <FdrHolidayFinderSwipingButtons
        swipeLeft={() => apiRef.current?.swipeLeft()}
        swipeRight={() => apiRef.current?.swipeRight()}
      />
    </FdrHolidayFinderVotingWrapper>
  );
}
