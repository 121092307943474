import React from 'react';
import {
  FdrTextBlock,
  IFdrTextBlockProps,
} from '@hotelplan/fdr.regular.basis.fdr-text-block';
import { useViewportSize } from '@hotelplan/libs.hooks-dom';
import { FdrImage } from '@hotelplan/supergraph-api';
import NoSsr from 'fdr/components/local/no-ssr/no-ssr';
import {
  TextBlockImage,
  FdrLocalStyledTextBlockWrapper,
} from './fdr-styled-text-block.styles';

interface IFdrStyledTextBlockProps extends IFdrTextBlockProps {
  fontColor: string;
  backgroundColor: string;
  backgroundImage: Omit<FdrImage, 'id'>;
}

export const FdrStyledTextBlock: React.FC<IFdrStyledTextBlockProps> = ({
  id,
  text,
  title,
  className,
  fontColor,
  backgroundImage,
  backgroundColor,
}) => {
  const { widthPx: viewportWidth = '100vw' } = useViewportSize();

  return (
    <FdrLocalStyledTextBlockWrapper
      data-id="text-block-wrapper"
      className={`${className ? className : ''} text-block-wrapper`}
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      backgroundImage={Boolean(backgroundImage)}
    >
      <NoSsr>
        <FdrTextBlock
          className="text-block"
          id={id}
          title={title}
          text={text}
        />
        {backgroundImage ? (
          <TextBlockImage
            image={backgroundImage}
            viewportWidth={viewportWidth}
            className="text-block-image"
          />
        ) : null}
      </NoSsr>
    </FdrLocalStyledTextBlockWrapper>
  );
};
