import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrLazyRating } from 'fdr/components/local/fdr-lazy-rating';

export const ProductCardImageWrap = styled.div.attrs({
  className: 'cardImageWrap',
})(
  sx2CssThemeFn({
    minHeight: [null, '200px'],
    position: 'relative',
    flexBasis: [null, '298px'],
    height: ['147px', '100%'],
    flexShrink: [null, '0'],
    borderRadius: '3px 3px 0 0',
    overflow: 'hidden',
    a: {
      height: '100%',
    },
  })
);

export const ProductTravelType = styled.span(
  ({ theme: { colors, space, FONT_SIZE } }) => ({
    ...FONT_SIZE.SMALL,
    padding: space[2],
    position: 'relative',
    top: '0px',
    borderRadius: '0 0 5px 5px',
    backgroundColor: colors.lightGreySecond,
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    '.delimiter': {
      padding: `0 ${space[1]}`,
    },
  })
);

export const TopOffer = styled.span.attrs({
  className: 'topOffer',
})(({ theme: { FONT_SIZE, colors } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.SMALL,
    padding: 2,
    paddingRight: '14px',
    paddingLeft: [2, '14px'],
    position: 'absolute',
    borderRadius: '0 0 5px 5px',
    backgroundColor: colors.mainRed,
    color: 'white',
    zIndex: 1,
    top: 0,
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
    '.icon': {
      verticalAlign: 'text-bottom',
      marginRight: 1,
    },
  })
);

export const ProductCardTitle = styled.h4.attrs({
  className: 'cardTitle',
  'data-id': `product-title`,
})(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.XS,
    marginBottom: [1, 0],
  })
);

export const ProductCardRating = styled(FdrLazyRating)(
  ({ theme: { colors, media, space } }) => ({
    marginBottom: space[2],
    color: colors.primary,
    '.rating': {
      marginRight: '3px',
    },
    '.remainRating': {
      marginRight: '3px',
      color: colors.lightGreyFirst,
    },
    [media.tablet]: {
      marginBottom: space[1],
    },
  })
);
