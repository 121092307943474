import React from 'react';
import styled from 'styled-components';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { IFdrProductCardProps } from './fdr-product-card.types';

const ProductLink = styled(FdrRoute)(
  sx2CssThemeFn({
    display: 'block',
    height: '100%',
    '&:hover': {
      color: 'defaultText',
    },
  })
);

export const FdrProductCardWrapper: React.FC<IFdrProductCardProps> = props => {
  const { link, ...otherProps } = props;

  if (!link) {
    return <div>{props.children}</div>;
  }

  return <ProductLink link={link} {...otherProps} />;
};
