import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const ContentLoaderWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'center',
    marginTop: ['30px', '60px'],
  })
);

interface IFdrHolidayFinderProductCardSkeletonProps {
  className?: string;
}

export function FdrHolidayFinderProductCardSkeleton({
  className,
}: IFdrHolidayFinderProductCardSkeletonProps): ReactElement {
  const { mobile } = useDeviceType();

  return (
    <ContentLoaderWrapper className={className}>
      <ContentLoader
        speed={2}
        width={mobile ? 342 : 460}
        height={mobile ? 525 : 641}
        style={{
          width: mobile ? '342px' : '460px',
          height: mobile ? '525px' : '641px',
        }}
      >
        {mobile ? (
          <>
            <circle cx="25" cy="500" r="25" />
            <circle cx="130" cy="465" r="30" />
            <circle cx="214" cy="465" r="30" />
            <circle cx="317" cy="500" r="25" />
            <rect x="29" y="70" width="141" height="33" rx="4" />
            <circle cx="39" cy="318" r="10" />
            <circle cx="62" cy="318" r="10" />
            <circle cx="85" cy="318" r="10" />
            <circle cx="108" cy="318" r="10" />
            <circle cx="130" cy="318" r="10" />
            <rect x="29" y="341" width="172" height="19" rx="8" />
            <rect x="223" y="376" width="92" height="19" rx="8" />
            <rect x="115" y="382" width="101" height="13" rx="6.5" />
            <rect x="9" y="40" width="325" height="1" />
            <rect x="9" y="406" width="325" height="1" />
            <rect x="9" y="40" width="1" height="366" />
            <rect x="333" y="40" width="1" height="366" />
            <rect x="23" y="18" width="295" height="1" />
            <rect x="33" y="15" width="277" height="1" />
            <rect x="33" width="277" height="1" />
            <rect x="23" y="37" width="295" height="1" />
            <rect x="23" y="18" width="1" height="19" />
            <rect x="33" width="1" height="16" />
            <rect x="309" width="1" height="16" />
            <rect x="317" y="18" width="1" height="19" />
          </>
        ) : (
          <>
            <circle cx="188" cy="611" r="30" />
            <circle cx="272" cy="611" r="30" />
            <rect x="20" y="70" width="141" height="33" rx="4" />
            <circle cx="30" cy="410" r="10" />
            <circle cx="53" cy="410" r="10" />
            <circle cx="76" cy="410" r="10" />
            <circle cx="99" cy="410" r="10" />
            <circle cx="121" cy="410" r="10" />
            <rect x="20" y="433" width="183" height="23" rx="8" />
            <rect x="349" y="469" width="92" height="19" rx="8" />
            <rect x="241" y="475" width="101" height="13" rx="6.5" />
            <path d="M17 24C17 21.7909 18.7909 20 21 20H439C441.209 20 443 21.7909 443 24V38H17V24Z" />
            <path d="M34 4C34 1.79086 35.7909 0 38 0H421C423.209 0 425 1.79086 425 4V18H34V4Z" />
            <rect y="40" width="460" height="1" />
            <rect y="499" width="460" height="1" />
            <rect y="40" width="1" height="459" />
            <rect x="459" y="40" width="1" height="459" />
          </>
        )}
      </ContentLoader>
    </ContentLoaderWrapper>
  );
}
