import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { FdrHolidayFinderInfoOverlay } from './fdr-holiday-finder-info-overlay';
import {
  FdrDislikeButton,
  FdrFunctionButton,
  FdrHolidayFinderControls,
  FdrLikeButton,
} from './fdr-holiday-finder.styles';

interface IFdrSwipingButtonsProps {
  swipeRight(): void;
  swipeLeft(): void;
}

export function FdrHolidayFinderSwipingButtons(
  props: IFdrSwipingButtonsProps
): ReactElement {
  return (
    <>
      <FdrHolidayFinderControls>
        <FdrDislikeButton
          testId="dislike-btn"
          icon={{ name: 'dislike' }}
          onClick={props.swipeLeft}
        />
        <FdrLikeButton
          testId="like-btn"
          icon={{ name: 'like' }}
          onClick={props.swipeRight}
        />
      </FdrHolidayFinderControls>
      <FdrHolidayFinderInfoOverlay />
    </>
  );
}

interface IFdrAddAllToWishlistButtonProps {
  onAddToWishlist(): void;
  loading: boolean;
}

export function FdrAddAllToWishlistButton({
  onAddToWishlist,
  loading,
}: IFdrAddAllToWishlistButtonProps): ReactElement {
  const [t] = useTranslation('holidayfinder');

  return (
    <FdrFunctionButton
      className="add-all-to-wishlist"
      onClick={onAddToWishlist}
    >
      {loading ? (
        <img
          className="hf-button-image"
          alt="LOADING"
          src="/images/spinner-button.svg"
          width="100%"
        />
      ) : (
        t('bulkAddToWishlist.button')
      )}
    </FdrFunctionButton>
  );
}
