export enum WishlistViewType {
  List = 'list',
  Compare = 'compare',
}

export enum WishlistSource {
  HF = 'HolidayFinder',
}

export const COMPARE_ITEMS_COUNT_MOBILE = 2;
export const COMPARE_ITEMS_COUNT_DESKTOP = 3;
export const COMPARE_ITEMS_MAX_COUNT = COMPARE_ITEMS_COUNT_DESKTOP;
export const WISHLIST_OFFER_DATE_FORMAT = 'dd.MM.yyyy';
