import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrTextBgImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-text-bg-image.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHolidayFinderResultsPageQueryVariables = Types.Exact<{
  textBgImageCriteria: Array<Types.FdrImageCriteria> | Types.FdrImageCriteria;
}>;

export type FdrHolidayFinderResultsPageQuery = {
  __typename?: 'Query';
  fdrHolidayFinder?: {
    __typename?: 'FdrHolidayFinderPages';
    resultPage?: {
      __typename?: 'FdrHolidayFinderResultPage';
      text?: string | null;
      title?: string | null;
      textFgColor?: string | null;
      textBgColor?: string | null;
      textBgImage?: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      } | null;
    } | null;
  } | null;
};

export const FdrHolidayFinderResultsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHolidayFinderResultsPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textBgImageCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FdrImageCriteria' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHolidayFinder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultPage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textFgColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textBgColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textBgImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrTextBgImage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrTextBgImageFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHolidayFinderResultsPageQuery__
 *
 * To run a query within a React component, call `useFdrHolidayFinderResultsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHolidayFinderResultsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHolidayFinderResultsPageQuery({
 *   variables: {
 *      textBgImageCriteria: // value for 'textBgImageCriteria'
 *   },
 * });
 */
export function useFdrHolidayFinderResultsPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrHolidayFinderResultsPageQuery,
    FdrHolidayFinderResultsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrHolidayFinderResultsPageQuery,
    FdrHolidayFinderResultsPageQueryVariables
  >(FdrHolidayFinderResultsPageDocument, options);
}
export function useFdrHolidayFinderResultsPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHolidayFinderResultsPageQuery,
    FdrHolidayFinderResultsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrHolidayFinderResultsPageQuery,
    FdrHolidayFinderResultsPageQueryVariables
  >(FdrHolidayFinderResultsPageDocument, options);
}
export type FdrHolidayFinderResultsPageQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderResultsPageQuery
>;
export type FdrHolidayFinderResultsPageLazyQueryHookResult = ReturnType<
  typeof useFdrHolidayFinderResultsPageLazyQuery
>;
export type FdrHolidayFinderResultsPageQueryResult = Apollo.QueryResult<
  FdrHolidayFinderResultsPageQuery,
  FdrHolidayFinderResultsPageQueryVariables
>;
