import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { useFdrHolidayFinderTeaserQuery } from 'fdr/schemas/query/holiday-finder/fdr-holiday-finder-teaser.generated';
import {
  FdrTeaserContent,
  FdrTeaserImageWrapper,
  FdrTeaserTextSection,
  FdrTeaserWrapper,
} from './fdr-holiday-finder.styles';

export function FdrHolidayFinderTeaser(): ReactElement {
  const [t, { language }] = useTranslation('holidayfinder');
  const { push } = useRouter();

  const fdrHolidayFinderTeaserQuery = useFdrHolidayFinderTeaserQuery();

  const teaserImage =
    fdrHolidayFinderTeaserQuery?.data?.fdrHolidayFinder?.landingPage?.hero ||
    null;

  const holidayFinderRoute = getRouteByPageTypeLocale<EPageType>(
    EPageType.HolidayFinderLanding,
    language
  );

  function handleButtonClick() {
    push(holidayFinderRoute.builder({}));
  }

  return (
    <FdrTeaserWrapper>
      <FdrTeaserImageWrapper>
        {teaserImage ? <FdrImage image={teaserImage} /> : null}
      </FdrTeaserImageWrapper>
      <FdrTeaserContent className="contest-teaser-content">
        <FdrTeaserTextSection>
          <span className="contest-teaser-title">
            {t('holidayfinder:contest.teaser.title')}
          </span>
          <span className="contest-teaser-text">
            {t('holidayfinder:contest.teaser.text')}
          </span>
        </FdrTeaserTextSection>
        <BsButton onClick={handleButtonClick}>
          {t('holidayfinder:contest.teaser.button')}
        </BsButton>
      </FdrTeaserContent>
    </FdrTeaserWrapper>
  );
}
