import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { formatPrice } from '@hotelplan/libs.utils';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import {
  ProductCardRating,
  ProductCardTitle,
  ProductTravelType,
} from 'components/domain/product-card/ProductCard.styles';
import {
  FdrHolidayFinderProductCardContentWrap,
  FdrProductCardImage,
  FdrTravelTypeIconWrapper,
} from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.styles';
import { mapPrice } from 'fdr/components/local/fdr-holiday-finder/fdr-holiday-finder.utils';
import { FdrOfferBreadcrumbs } from 'fdr/components/local/fdr-offer';
import { TFdrVotingCard } from './fdr-voting.types';

interface IFdrProductCardProps {
  offer: TFdrVotingCard;
}

export function FdrHolidayFinderProductCard({
  offer,
}: IFdrProductCardProps): ReactElement {
  const [t] = useTranslation();

  if (offer.product.__typename !== 'FdrHotel') return null;

  const product = offer.product;

  const image = product.hero;
  const rating = product.rating || 0;
  const isPackage = offer.travelType === FdrProductTravelType.Package;
  const price = offer.pricePerPerson;
  const formattedDuration = t('common:nights.count', {
    count: offer.duration,
  });

  const { destination } = product.resort || {};

  const breadcrumbs = [
    destination?.country?.name,
    destination?.name,
    product.resort?.name,
  ];

  return (
    <FdrHolidayFinderProductCardContentWrap>
      <ProductTravelType className="travel-type">
        <FdrTravelTypeIconWrapper>
          <BsIcon
            name={isPackage ? 'flug' : 'hotel'}
            className="travel-type-icon"
          />
        </FdrTravelTypeIconWrapper>
        <span>{isPackage ? t('common:flight') : t('common:hotel')}</span>
        {isPackage && (
          <>
            <div className="delimiter"> + </div>
            <FdrTravelTypeIconWrapper>
              <BsIcon name={'hotel'} className="travel-type-icon" />
            </FdrTravelTypeIconWrapper>
            <span>{t('common:hotel')}</span>
          </>
        )}
      </ProductTravelType>
      <ProductCardRating
        maxRating={5}
        rating={rating}
        ratingContent={<BsIcon name="hotelplanbewertung" />}
        halfWayRatingContent={<BsIcon name="hotelplanbewertung-outlined" />}
        className="rating"
      />
      <ProductCardTitle>
        <FdrOfferBreadcrumbs
          breadcrumbs={breadcrumbs.map(caption => ({
            caption,
          }))}
        />
      </ProductCardTitle>
      <p className="travel-period">
        <span className="travel-duration">{formattedDuration}</span>
        {price && (
          <>
            <span className="price-prefix">{t('common:perPerson.from')}</span>
            <span data-id="product-price" className="price">
              {formatPrice(mapPrice(price))}
            </span>
          </>
        )}
      </p>
      {image ? <FdrProductCardImage image={image} /> : null}
    </FdrHolidayFinderProductCardContentWrap>
  );
}
