import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrStyledTextBlock } from 'fdr/components/local/fdr-styled-text-block';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { BsCircleButton } from '@hotelplan/core.fusion.bs-circle-button';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';

export const FdrTeaserWrapper = styled.div(({ theme: { media } }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  paddingBottom: '50px',
  borderRadius: '3px',
  [media.tablet]: {
    width: '970px',
    height: '360px',
    flexDirection: 'row',
    paddingBottom: '0px',
  },
}));

export const FdrTeaserImageWrapper = styled.div(
  sx2CssThemeFn({
    flexBasis: ['250px', '570px'],
    flexShrink: 0,
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'lightGreySecond',
    img: {
      height: ['250px', '100%'],
      width: '100%',
      objectFit: 'cover',
      display: 'block',
      borderRadius: '3px 0 0 3px',
    },
  })
);

export const FdrTeaserContent = styled.div(
  sx2CssThemeFn({
    height: ['205px', '100%'],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: ['20px', '40px'],
    border: '1px solid #E1E1E1',
    borderLeft: [null, 'none'],
    borderRadius: ['0 0 3px 3px', '0 3px 3px 0'],
  })
);

export const FdrTeaserTextSection = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    display: [null, 'flex'],
    flexDirection: [null, 'column'],
    '.contest-teaser-title': FONT_SIZE.S,
    '.contest-teaser-text': {
      ...FONT_SIZE.LARGE,
      pt: [null, 2],
    },
  })
);

export const FdrHolidayFinderLandingWrapper = styled.div<{
  windowRatio: number;
}>(({ windowRatio }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column', 'row'],
    backgroundColor: 'lightGreySecond',
    height: [null, 'calc(100vh - 106px)'],
    pb: [`${windowRatio < 0.5 ? '110px' : '40px'}`, 0],
  })
);

export const FdrLandingImageWrapper = styled.div(
  sx2CssThemeFn({
    flexBasis: ['100vw', '50%'],
    flexShrink: 0,
    backgroundRepeat: 'no-repeat',
    img: {
      height: ['100vw', '100%'],
      objectFit: 'cover',
      display: 'block',
    },
  })
);

export const FdrHolidayFinderWelcomeBlock = styled.div(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      pt: ['40px', 0],
      position: [null, 'relative'],
      '.text-block-text': {
        textAlign: 'center',
        pt: 3,
        px: 4,
        maxWidth: 'initial',
        ...FONT_SIZE.LARGE,
      },
      '.text-block-wrapper': {
        marginBottom: [4, 6],
        mx: 0,
      },
      '.text-block': {
        mx: 0,
      },
      '.text-block-title': {
        ...FONT_SIZE.L,
        textAlign: 'center',
        mb: 0,
      },
      '.btn-submit': {
        px: '70px',
      },
      '.info-button': {
        position: [null, 'absolute'],
        left: [null, 5],
        bottom: [null, 3],
      },
    })
);

export const FdrHolidayFinderTextBlock = styled(FdrStyledTextBlock)(
  sx2CssThemeFn({
    mx: 0,
    width: '100%',
    maxWidth: 'initial',
    boxShadow: 'none',
    clipPath: 'none',
    '.text-block': {
      mx: 0,
      width: '100%',
      maxWidth: 'initial',
    },
    '.text-block-image': {
      width: '100%',
    },
    '.text-block-title, .text-block-text': {
      backgroundColor: 'lightGreySecond',
    },
  })
);

export const FdrInfoButton = styled(BsCircleButton)(
  sx2CssThemeFn({
    position: 'fixed',
    zIndex: 10,
    bottom: '82px',
    left: '15px',
    color: 'primary',
    backgroundColor: 'primary',
  })
);

export const FdrInfoOverlayWrap = styled(WhiteBoxModal)(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      '.modal-header': {
        pt: ['28px', '14px'],
        px: [4, '14px'],
        borderBottom: 'none',
      },
      '.modal-wrapper': {
        maxWidth: [null, '770px'],
      },
      '.modal-body': {
        px: [4, '100px'],
        pt: [4, '30px'],
        pb: ['90px', '70px'],
      },
      '.info-overlay-title': {
        ...FONT_SIZE.M,
        mb: [4, 3],
      },
      '.info-overlay-text': {
        ...FONT_SIZE.LARGE,
        mb: [4, 3],
        li: {
          listStyle: 'auto',
          ml: '20px',
        },
        '.bold': {
          fontWeight: 'bold',
        },
        a: {
          color: 'primary',
        },
      },
    })
);

export const FdrUnderstoodButton = styled(BsButton)(
  sx2CssThemeFn({
    width: ['100%', '240px'],
    borderRadius: ['0px', '6px'],
    mx: [null, 'auto'],
    display: 'block',
    mb: [null, '50px'],
  })
);

export const FdrSwitchButtonsWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    mb: 6,
    '.switch-button': {
      border: '1px solid #000000',
      p: '6px 20px',
      borderRadius: '30px',
      '&.active': {
        backgroundColor: '#000000',
        color: '#ffffff',
      },
    },
  })
);

export const FdrHolidayFinderVotingWrapper = styled.div(
  ({ theme: { colors } }) => ({
    backgroundColor: colors.lightGreySecond,
    flex: 1,
    overflow: 'hidden',
  })
);

export const FdrHolidayFinderControls = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    '> button': {
      mt: 0,
      width: '60px',
      height: '60px',
      cursor: 'pointer',
      boxShadow: 'none',
    },
  })
);

export const FdrLikeButton = styled(BsCircleButton)(({ theme: { space } }) => ({
  '> .icon': {
    height: '30px',
    width: '30px',
  },
  backgroundColor: 'white',
  marginLeft: space[4],
}));

export const FdrDislikeButton = styled(BsCircleButton)({
  '> .icon': {
    height: '25px',
    width: '25px',
  },
  backgroundColor: 'white',
});

export const FdrCardImage = styled(FdrImage)(({ theme: { radii } }) => ({
  width: '100%',
  height: '100%',
  userSelect: 'none',
  objectFit: 'cover',
  borderRadius: radii.default,
}));

export const FdrFilterCardWrapper = styled.div(
  sx2CssThemeFn({
    border: 'none',
    height: '100%',
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: [null, '460px'],
    userSelect: 'none',
    img: {
      height: '100%',
      width: '100%',
      display: 'block',
      objectFit: 'cover',
      borderRadius: 'default',
    },
  })
);

export const FdrTextBlockWrapper = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '60%',
    width: '100%',
    '.card-text': {
      textAlign: 'center',
      color: 'white',
      fontSize: ['20px', '30px'],
      maxWidth: '80%',
    },
    '.icon': {
      color: 'white',
      marginBottom: '20px',
      width: '23px',
      height: '23px',
    },
  })
);

export const FdrTravelDatesMobileModal = styled(WhiteBoxModal)(
  sx2CssThemeFn({
    '.modal-body': {
      overflowY: 'hidden',
    },
    '.wrapper': {
      display: 'block',
    },
    '.header': {
      order: 0,
      position: `fixed`,
      bottom: 'auto',
      top: '55px',
      display: 'block',
      pt: 3,
      pr: 0,
      left: 0,
      width: '100%',
    },
    '.footer': {
      display: 'block',
      boxShadow: 'topMedium',
      p: 0,

      '.travel-dates-information': {
        position: 'static',
        textAlign: 'center',
        fontSize: 1,
      },
      '.applyButton': {
        m: 0,
        width: '100%',
        borderRadius: 0,
      },
    },
    '.input': {
      py: '9px',
    },
    '.chevron': {
      bottom: '16px',
    },
    '.week-day-list-wrapper': {
      fontSize: 1,
      borderBottom: '1px solid',
      borderColor: 'lightGreyFourth',
      position: 'relative',
      pt: '4px !important',
      pb: '16px !important',
      top: 0,
      '&:after': {
        height: 0,
      },
    },
    '.header-group': {
      display: `grid`,
      gridTemplateColumns: '1fr 1fr',
      justifyContent: 'space-between',
      px: 3,
      flexDirection: 'row',
      alignItems: 'center',
      flexBasis: [null, '50%'],
      '.label-flexible, .label-exact': {
        mb: 0,
      },
      '.travel-dates-duration-dropdown': {
        maxWidth: '200px !important',
      },
    },
  })
);

export const FdrTravelDatesDesktopModal = styled(WhiteBoxModal)(({ theme }) =>
  sx2CssThemeFn({
    '.modal-wrapper': theme.modals.smallModalWrapper,
    '.wrapper': {
      '.body': {
        px: '18px',
      },
      '.travel-dates-duration-dropdown': {
        maxWidth: '200px',
      },
    },
    '.header': {
      order: 0,
      position: 'relative',
      top: 'auto',
      pt: '20px',
    },
    '.footer': {
      boxShadow: '0 -2px 7px 1px rgba(0,0,0,0.09)',
      px: 0,
      py: 0,
      display: 'initial',
      '&:after': {
        content: '',
        width: 0,
      },
      '.travel-dates-information': {
        color: 'secondary',
      },
      '.applyButton': {
        width: '100%',
        borderRadius: 0,
      },
    },
    '.DayPicker-wrapper': {
      borderBottom: 'none',
    },
    '.input': {
      py: 2,
    },
    '.chevron': {
      bottom: '16px',
    },
    '.header-group-duration-dropdown': {
      flexGrow: 'initial',
    },
    '.header-group': {
      display: [`grid`, 'flex'],
      gridTemplateColumns: ['1fr 1fr', ''],
      justifyContent: 'space-between',
      px: [3, '27px'],
      flexDirection: 'row',
      alignItems: 'center',
      flexBasis: [null, '50%'],
      '.label-flexible, .label-exact': {
        mb: 0,
      },
    },
  })
);

export const FdrProductCardImage = styled(FdrImage)(({ theme: { radii } }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  zIndex: -1,
  objectFit: 'cover',
  borderRadius: radii.default,
}));

export const FdrHolidayFinderProductCardContentWrap = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '30px 20px 10px',
    height: '100%',
    userSelect: 'none',
    '.cardTitle': {
      color: 'white',
    },
    '.rating': {
      marginTop: 'auto',
    },
    '.travel-type': {
      borderRadius: '4px',
      padding: '6px 8px',
    },
    '.travel-period': {
      display: 'flex',
      alignItems: 'flex-end',
      alignSelf: 'flex-end',
      fontSize: '14px',
      color: 'white',
      paddingTop: 1,
    },
    '.travel-duration, .price-prefix': {
      marginRight: 1,
    },
    '.price': {
      fontSize: '20px',
      color: 'white',
      lineHeight: 1.3,
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      zIndex: -1,
      backgroundImage:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 50.11%, rgba(0, 0, 0, 0.7) 100%)',
    },
  })
);

export const FdrTravelTypeIconWrapper = styled.span({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row-reverse',
  marginRight: '5px',
  '.travel-type-icon': {
    verticalAlign: 'sub',
  },
});

export const FdrHolidayFinderResultsSection = styled.section(
  sx2CssThemeFn({
    backgroundColor: 'lightGreySecond',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    px: [4, 0],
  })
);

export const FdrHolidayFinderItemWrap = styled.div(
  sx2CssThemeFn({
    maxWidth: '100%',
    width: ['100%', `${100 / 3}%`],
    px: [null, '15px'],
    mb: [4, '35px'],
    '.product-fav-container': {
      height: '100%',
      '.wishlist-icon': {
        top: [null, '200px'],
      },
    },
  })
);

export const FdrHolidayFinderResultsDetails = styled.div(
  sx2CssThemeFn({
    borderTop: '1px solid',
    pt: [4, '60px'],
    mb: [4, '35px'],
  })
);

export const FdrHolidayFinderResultsInfoWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    '.add-all-to-wishlist': {
      minWidth: [null, '300px'],
      mt: [4, null],
    },
  })
);

export const FdrHolidayFinderResultsTextBlock = styled.div(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      maxWidth: [null, '800px'],
      variant: 'text.richText',
      py: ['40px', '60px'],
      h2: FONT_SIZE.M,
      p: { ...FONT_SIZE.LARGE, pb: 2 },
      '.holiday-finder-text-block-wrapper': {
        mx: 0,
      },
      '.text-block-text': {
        pt: 2,
      },
    })
);

export const FdrHolidayFinderResultsSectionWrapper = styled.div(
  sx2CssThemeFn({
    textAlign: [`left`, `right`],
    pb: [4, 0],
    maxWidth: '100%',
    '> div': {
      p: 0,
    },
  })
);

export const FdrHolidayFinderResultsWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    mx: [null, '-15px'],
    mb: [3, null],
    '.pagination-buttons': {
      pt: ['20px', '2px'],
      mb: ['40px', '70px'],
      mx: 'auto',
      button: {
        paddingTop: '0',
      },
    },
  })
);

export const FdrResultsInfoWrapper = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.S,
    '> .products-count': {
      fontWeight: 'bold',
      color: 'black',
    },
  })
);

export const FdrHolidayFinderFunctionCardsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column', 'row'],
    justifyContent: 'space-between',
    gap: 4,
    marginBottom: ['40px', '60px'],
  })
);

export const FdrFunctionCardWrapper = styled.div({
  position: 'relative',
  width: '100%',
  height: '325px',
});

export const FdrFunctionCardContent = styled.div(
  ({ theme, theme: { FONT_SIZE, radii } }) =>
    sx2CssThemeFn({
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: ['0px 24px 24px 24px', '0px 40px 24px 40px'],
      textAlign: 'center',
      borderRadius: radii.default,
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) -1.38%, rgba(0, 0, 0, 0.5) 100%)',
      '.function-button-text': {
        ...FONT_SIZE.S,
        color: 'white',
        marginTop: 'auto',
        mb: [null, 4],
      },
      '.add-all-to-wishlist': {
        width: ['100%', 'auto'],
      },
      '.restart-text': {
        mb: [null, 6],
      },
      button: {
        ...theme.buttons[E_BUTTON_TYPE.TEASER_DARK],
        '&:hover:not([disabled])': {
          backgroundColor: 'transparent',
        },
      },
    })
);

export const FdrFunctionButton = styled(BsButton)(
  sx2CssThemeFn({
    px: [null, '50px'],
    width: ['100%', 'auto'],
    mt: [3, 0],
    '.hf-button-image': {
      width: '50px',
      height: '14px',
      verticalAlign: 'middle',
    },
  })
);

export const FdrFunctionButtonImage = styled(FdrImage)(
  ({ theme: { radii } }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: radii.default,
  })
);

export const FdrOfferTypesAndMealType = styled.div(
  ({ theme: { FONT_SIZE } }) => ({
    ...FONT_SIZE.MEDIUM,
  })
);
