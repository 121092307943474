import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import styled from 'styled-components';

export const FdrLocalStyledTextBlockWrapper = styled.div<{
  fontColor: string;
  backgroundColor: string;
  backgroundImage: boolean;
}>(
  ({ theme }) => theme.text.richText,
  ({ fontColor, backgroundColor, backgroundImage, theme: { FONT_SIZE } }) =>
    sx2CssThemeFn(
      {
        position: 'relative',
        zIndex: 1,
        backgroundColor,
        color: fontColor,
        '.text-block .text-block-title': { ...FONT_SIZE.M, marginBottom: 0 },
      },
      {
        ...(backgroundColor || backgroundImage
          ? {
              boxShadow: backgroundImage
                ? null
                : `0 0 0 100vmax ${backgroundColor}`,
              clipPath: `inset(0 -100vmax)`,
            }
          : {}),
      }
    ),
  ({ theme }) => theme.text.textBlockWrap || {}
);

export const TextBlockImage = styled(FdrImage)<{ viewportWidth: string }>(
  ({ viewportWidth }) =>
    sx2CssThemeFn({
      position: 'absolute',
      objectFit: 'cover',
      display: 'block',
      width: viewportWidth,
      height: '100%',
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      left: '50%',
      transform: 'translateX(-50%)',
      maxWidth: 'none',
    })
);
